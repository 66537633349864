<template>
  <div class="terms-area wrap_policy">
    <strong id="useterms01_01" class="tit_subject">제1조 (목적)</strong>
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>이 약관은 (주)서울옥션(이하 ‘회사’라
        합니다)이 운영하는 블랙랏 웹사이트 및 모바일 애플리케이션에서 제공하는
        전자상거래 관련 서비스(이하 ‘서비스’라 합니다)를 이용하는 자 및 회사
        사이의 권리·의무 및 책임사항과 서비스 이용 절차에 관한 사항을 규정함을
        목적으로 합니다.
      </li>
      <li>
        <span class="txt_num">②</span>회사는 통신판매중개자로서 거래당사자가
        아니며, 판매자가 등록한 상품정보 및 거래에 대하여 본 약관에 명시돼 있는
        내용 이외의 책임을 지지 않습니다.
      </li>
    </ul>
    <strong id="useterms01_02" class="tit_subject">제2조 (정의)</strong>
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>회원: 본 약관에 동의하고 회사와 서비스
        이용계약을 체결한 자로서, 판매자와 구매자가 이에 해당합니다.
      </li>
      <li>
        <span class="txt_num">②</span>판매자: 물품을 판매하기 위해 회사의 판매자
        등록 절차를 완료한 회원을 말합니다. 물품의 통신판매를 업(業)으로 하는
        판매자(개인 혹은 법인 여부 불문)인 경우와 그렇지 아니한 판매자인 경우
        판매자 등록 절차와 이용하는 서비스에 차이가 있을 수 있습니다. 판매자는
        셀러(Seller)나 파트너(Partner)로 지칭되기도 합니다.
      </li>
      <li>
        <span class="txt_num">③</span>구매자: 판매자가 판매하는 물품을
        구매하고자 하는 회원을 말합니다. 비더(Bidder)나 응찰자도 동일한
        의미입니다.
      </li>
      <li>
        <span class="txt_num">④</span>위탁자: 본인 소유의 상품을 회사의 기획경매
        방법에 의하여 판매하고자 하는 사람을 말합니다.
      </li>
      <li>
        <span class="txt_num">⑤</span>응찰: 경매물품을 구매하기 위하여 원하는
        매수가격을 제출하는 행위를 말합니다. 비드(Bid) 혹은 비딩(Bidding) 혹은
        입찰도 동일한 의미입니다.
      </li>
      <li>
        <span class="txt_num">⑥</span>비드나우(Bid Now): 경매 방식을 통해 물품을
        판매하거나 구매하는 행위를 말합니다.
      </li>
      <li>
        <span class="txt_num">⑦</span>바이나우(Buy Now): 물품을 즉시 구매하는
        행위를 말합니다. 즉시 구매도 동일한 의미입니다.
      </li>
      <li>
        <span class="txt_num">⑧</span>메이크오퍼(Make Offer): 구매자가 구매하길
        원하는 금액을 직접 제안하는 것을 말합니다.
      </li>
      <li>
        <span class="txt_num">⑨</span>프리미엄 랏(Premium Lot): 경매 시작가가
        1,000만원 이상인 상품을 말합니다.
      </li>
      <li>
        <span class="txt_num">⑩</span>자동응찰: 회사 시스템상 정해진 응찰 폭에
        따라 순차적으로 응찰하는 것과 달리, 응찰자가 희망하는 최고 응찰가를
        설정해 두고 응찰하는 방식을 말합니다. 자동응찰액을 기입한 경우 해당
        경매의 응찰가가 자동응찰액으로 입력한 응찰가가 될 때까지 지속적으로 자동
        조정됩니다. 다른 응찰자의 참여 여부에 따라 자동응찰액 이하에서 낙찰될
        수도 있습니다. 동일한 자동응찰가격의 경우는 먼저 자동응찰에 참여한
        응찰자에게 낙찰됩니다.
      </li>
      <li>
        <span class="txt_num">⑪</span>낙찰: 응찰에 의하여 경매물품의 거래가
        성립되는 것을 말합니다.
      </li>
      <li>
        <span class="txt_num">⑫</span>서비스 수수료: 회원이 상품의 판매, 구매 및
        제반 서비스를 이용함에 따라 부과되는 시스템이용료로, 등록수수료,
        판매수수료, 부가서비스 수수료 등이 있습니다. 관련 내용은 본 약관 관련
        조항 및 해당 서비스 화면에 명시된 바에 따릅니다.
      </li>
    </ul>
    <strong id="useterms01_03" class="tit_subject"
      >제3조 (약관 등의 명시와 설명 및 개정)</strong
    >
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>회사는 본 약관의 내용을 회원이 쉽게 확인할
        수 있도록 회사 블랙랏 웹페이지의 초기화면에 게시합니다.
      </li>
      <li>
        <span class="txt_num">②</span>회사는 ‘전자상거래 등에서의 소비자보호에
        관한 법률’, ‘약관의 규제에 관한 법률’, ‘전자금융거래법’, ‘소비자기본법’
        등 관련 법령을 위반하지 않는 범위 내에서 본 약관을 개정할 수 있습니다.
      </li>
      <li>
        <span class="txt_num">③</span>회사가 이 약관을 개정할 경우에는 적용일자
        및 개정사유를 명시하여 그 적용일자 7일 이전부터 적용일자 전일까지
        공지합니다. 다만, 개정약관의 내용이 회원에게 불리한 경우에는 최소한 30일
        이상의 사전 유예기간을 두고 블랙랏 웹페이지 및 애플리케이션 화면에
        게시하거나 기타 방법으로 공지합니다. 이 경우 개정 전 내용과 개정 후
        내용을 명확하게 비교하여 회원이 알기 쉽게 표시합니다.
      </li>
      <li>
        <span class="txt_num">④</span>회사가 약관을 개정할 경우에는 그
        개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미
        체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다.
      </li>
      <li>
        <span class="txt_num">⑤</span>회사가 전항에 따라 개정약관을 회원에게
        공지했음에도 개정약관 시행일 이후 7일이 지나는 날까지 회원이 거부 의사를
        표시하지 않을 경우 개정 약관에 동의한 것으로 간주합니다. 회원이 개정
        약관에 동의하지 않을 경우 해당 회원은 서비스 이용 계약을 해지할 수
        있습니다.
      </li>
      <li>
        <span class="txt_num">⑥</span>이 약관에서 정하지 아니한 사항과 이 약관의
        해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제
        등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자
        보호지침 및 관련법령 또는 상관례에 따릅니다. 회원은 회사가 제공하는
        서비스를 이용함에 있어서 관련법령을 준수하여야 하며, 본 약관의 규정을
        들어 관련법령 위반에 대한 면책을 주장할 수 없습니다.
      </li>
    </ul>
    <strong id="useterms01_04" class="tit_subject"
      >제4조 (서비스의 제공)</strong
    >
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>회사는 회원에게 다음과 같은 서비스를
        제공합니다.
        <ul class="list_2depth_type2">
          <li>
            <span class="txt_num">1.</span>전자상거래 플랫폼 개발 및 운영
            서비스: 블랙랏 웹사이트와 애플리케이션을 통하여 물품 경매와 매매
            거래가 이루어질 수 있도록 사이버 거래 장소(마켓플레이스)를 제공하는
            서비스 및 관련 부가서비스 일체
          </li>
          <li>
            <span class="txt_num">2.</span>정보 제공 서비스: 회사가 블랙랏
            웹사이트를 통해 회원에게 온라인으로 제공하는 정보 및 콘텐츠 제공
            서비스
          </li>
          <li>
            <span class="txt_num">3.</span>기획 서비스: 회사가 기획하여 진행하는
            경매 및 실물 전시, 판매 등의 서비스
          </li>
          <li>
            <span class="txt_num">4.</span>광고 서비스: 회원이 회사에 광고
            수수료를 지급하고 자신의 마켓이나 자신이 판매하는 물품을 다른
            회원에게 광고할 수 있는 서비스
          </li>
          <li>
            <span class="txt_num">5.</span>기타 서비스: 회사가 거래 활성화를
            위해 제공하는 기타 서비스
          </li>
        </ul>
      </li>
      <li>
        <span class="txt_num">②</span>회사는 연중무휴 서비스를 제공함을 원칙으로
        하되, 컴퓨터 등 정보통신설비의 보수나 점검, 교체, 고장 등 운영상 상당한
        이유가 있는 경우 회원에게 통지한 후 서비스 제공을 일시적으로 중단할 수
        있습니다. 다만, 회사가 사전에 서비스의 중단을 통지할 수 없었던 부득이한
        사유가 있는 경우 사후에 통지할 수 있습니다.
      </li>
      <li>
        <span class="txt_num">③</span>회사는 서비스 제공을 위한 별도의 계약
        종료, 신규 서비스 개시, 기존 서비스 개정 등의 사유로 서비스의 내용을
        변경할 수 있습니다. 서비스 내용에 변경이 있는 경우 회사는 변경된
        서비스가 적용되기 30일 이전부터 적용일 이후 상당한 기간까지 웹사이트 및
        애플리케이션을 통해 회원들에게 변경된 서비스의 내용을 공지합니다. 다만
        회원의 거래와 관련하여 중대한 영향을 미치는 변경 사항이 있는 경우
        회원에게 별도로 통지합니다.
      </li>
    </ul>
    <strong id="useterms02_05" class="tit_subject"
      >제5조 (대리 및 보증의 부인)</strong
    >
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>회사는 판매자와 구매자 간의 편리한 상품의
        거래와 송금을 위한 시스템을 운영 및 관리, 제공할 뿐이며, 판매자 또는
        구매자를 대리하지 않습니다. 또한, 판매자와 구매자 사이에 성립된 상품
        거래와 송금에 관련한 책임과 판매자 및 구매자가 제공한 정보에 대한 책임은
        해당 판매자 및 구매자가 직접 부담하여야 합니다.
      </li>
      <li>
        <span class="txt_num">②</span>회사는 블랙랏 웹페이지를 통하여 이루어지는
        판매자와 구매자간의 상품의 거래와 관련하여 판매의사 또는 구매의사의 존부
        및 진정성, 상품의 품질, 완전성, 안전성, 적법성 및 타인의 권리에 대한
        비침해성, 이용회원 또는 판매자회원이 입력한 정보 및 그 정보를 통하여
        링크된 URL에 게재된 자료의 진실성 또는 적법성 등 일체에 대하여 보증하지
        아니하며, 이와 관련한 일체의 위험은 해당 판매자 또는 구매자가
        부담합니다.
      </li>
    </ul>
    <strong id="useterms02_06" class="tit_subject"
      >제6조 (서비스 이용계약의 체결)</strong
    >
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>회원은 회사가 정한 가입 양식에 따라
        회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로써 회원가입을
        신청합니다.
      </li>
      <li>
        <span class="txt_num">②</span>회원가입을 하기 위해서는 회사가 정한
        온라인 회원가입 신청서에 필수 기재사항을 입력하고 회사가 요구하는 인증
        절차를 완료하여야 합니다.
      </li>
      <li>
        <span class="txt_num">③</span>아이디(ID)는 실명 1인당 1개만을 사용할 수
        있으며, 이용계약은 아이디(ID) 단위로 체결하는 것을 원칙으로 합니다.
      </li>
      <li>
        <span class="txt_num">④</span>회원가입 신청을 위해 필수적으로 기재한
        항목이 변경된 경우(폐업 등의 사유 발생 포함) 해당 회원은 위 변경 사실을
        직접 수정하거나 회사에 1:1문의 또는 전화를 통해 통지하여야 하며,
        통지하지 않음으로 인하여 발생한 회원의 불이익에 관하여는 회원이 책임을
        집니다.
      </li>
      <li>
        <span class="txt_num">⑤</span>사가 정한 필수 기재사항을 입력하지 않거나
        허위의 정보를 입력한 경우, 회원가입 신청을 하려는 자가 만 14세 미만일
        경우에는 회원가입 신청이 제한될 수 있습니다.
      </li>
      <li>
        <span class="txt_num">⑥</span>회사는 회원에게 유익한 정보를 제공하기
        위해 위 필수 기재사항 이외에도 회원가입을 신청하는 자에게 취미, 관심사
        등 정보 제공을 요청할 수 있으나 회원가입을 신청하는 자는 위 정보의
        제공을 거절할 수 있습니다.
      </li>
      <li>
        <span class="txt_num">⑦</span>회사는 다음과 같은 사유가 있는 경우
        회원가입 신청의 승낙을 거절 또는 보류할 수 있고, 이 경우 회사는 승낙
        거절 또는 보류의 사유와 승낙에 필요한 추가 요청 정보 등 관련 사항을
        통지합니다.
        <ul class="list_2depth_type2">
          <li>
            <span class="txt_num">1.</span>실명 확인절차에서 실명 가입신청이
            아님이 확인된 경우
          </li>
          <li>
            <span class="txt_num">2.</span>이미 가입된 회원과 이름 및 고유
            식별코드가 동일한 경우
          </li>
          <li>
            <span class="txt_num">3.</span>가입신청 시 허위 정보를 기재한 경우
          </li>
          <li>
            <span class="txt_num">4.</span>기타 위법 또는 부당한 이용신청임이
            확인된 경우
          </li>
          <li>
            <span class="txt_num">5.</span>본 약관에 따라 회원 자격을 상실하거나
            이용 계약이 해지된 적이 있는 경우
            <small>(단, 회사의 회원 재가입 승낙을 얻은 경우는 예외)</small>
          </li>
          <li><span class="txt_num">6.</span>회사의 설비에 여유가 없는 경우</li>
          <li>
            <span class="txt_num">7.</span>회사의 서비스 제공에 기술적인 문제가
            있는 경우
          </li>
          <li>
            <span class="txt_num">8.</span>기타 회사의 합리적인 판단 하에
            거절이나 보류할 만한 이유가 있다고 인정하는 경우
          </li>
        </ul>
      </li>
    </ul>
    <strong id="useterms01_07" class="tit_subject">제7조 (판매자 등록)</strong>
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>회사로부터 회원가입 신청을 승낙 받아 이용
        계약이 성립된 회원이 회사의 서비스를 이용해 다른 회원에게 물품을
        판매하고자 할 경우 판매자 등록 절차를 거쳐야 합니다. 판매자가 서비스
        이용을 위해 입력하여 제공한 신원정보는 판매자의 물품에 대한 구매 청약을
        한 구매자의 요청에 따라 해당 구매자에게 제공될 수 있습니다.
      </li>
      <li>
        <span class="txt_num">②</span>판매자 등록을 하기 위해서는 회사가 정한
        온라인 등록 신청서에 필수 기재사항을 입력하여야 하며, 판매를 업으로 하는
        판매자의 경우 다음 정보가 회원에게 공개될 수 있습니다.
        <ul class="list_2depth_type2">
          <li>
            <span class="txt_num">1.</span>이름(법인인 경우 상호 및 대표자 이름)
          </li>
          <li><span class="txt_num">2.</span>주소</li>
          <li><span class="txt_num">3.</span>전화번호</li>
          <li><span class="txt_num">4.</span>이메일 주소</li>
        </ul>
      </li>
    </ul>
    <strong id="useterms01_08" class="tit_subject"
      >제8조 (이용 계약의 종료 및 회원 자격 상실)</strong
    >
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>회원의 해지
        <ul class="list_2depth_type2">
          <li>
            <span class="txt_num">1.</span>회원은 언제든지 회사에 해지의 의사를
            통지함으로써 이용 계약을 해지할 수 있습니다. 다만 회원은 회사의 해지
            의사를 통지하기 전에 구매 또는 판매 진행 중인 경매절차를 규정에 따라
            모두 완료, 철회 또는 취소하여야 하며, 철회 또는 취소로 인한 불이익은
            해당 회원이 부담합니다. 이용계약의 종료와 관련하여 발생한 손해는
            이용계약이 종료된 해당 회원이 책임을 부담하여야 합니다.
          </li>
          <li>
            <span class="txt_num">2.</span>이용계약은 회원의 해지의사가 회사에
            도달한 때에 종료됩니다. 다만 다음 사유가 있는 경우 이용 계약 해지
            절차를 보류할 수 있습니다.
            <ul class="list_3depth_type">
              <li>
                <span class="txt_num">a.</span>이용 계약 해지의 의사표시를 한
                자가 회원인지 여부를 확인할 수 없는 경우
              </li>
              <li>
                <span class="txt_num">b.</span>이용 계약 해지의 의사표시를 한
                회원의 물품 거래절차가 모두 완료되지 않은 경우
              </li>
              <li>
                <span class="txt_num">c.</span>회원이 서비스 이용에 관하여
                회사에 변제할 채무가 남아있는 경우
              </li>
              <li>
                <span class="txt_num">d.</span>이용 계약 해지의 의사표시를 한
                회원에 관한 거래 분쟁 문제가 남아 있는 경우
              </li>
            </ul>
          </li>
          <li>
            <span class="txt_num">3.</span>해지를 한 회원은 이 약관이 정하는
            회원 가입절차와 관련 조항에 따라 회원으로 다시 가입할 수 있습니다.
          </li>
        </ul>
      </li>
      <li>
        <span class="txt_num">②</span>회사의 해지
        <ul class="list_2depth_type2">
          <li>
            <span class="txt_num">1.</span>회사는 본 약관 또는 운영정책에 따라
            다음의 사유 및 기타 회사에서 판단하여 합당한 사유가 발생한 경우
            회원의 자격을 박탈하고 이용계약을 해지할 수 있습니다. 이 경우 회사는
            회원에게 해지사유를 밝혀 해지의사를 통지합니다.
            <ul class="list_3depth_type">
              <li>
                <span class="txt_num">a.</span>제6조 ⑦에 해당하는 이용계약 승낙
                거부 사유가 있음이 확인된 경우
              </li>
              <li>
                <span class="txt_num">b.</span>매매 부적합 물품을 판매
                등록하거나, 기타 공공질서 및 미풍양속에 위배되는 물품 거래행위를
                하거나 시도한 경우
              </li>
              <li>
                <span class="txt_num">c.</span>회사와 다른 회원, 또는 기타
                타인의 권리와 명예나 정당한 이익을 침해하는 행위를 한 경우
              </li>
              <li>
                <span class="txt_num">d.</span>직거래 등 회사가 제공하는
                서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우
              </li>
              <li>
                <span class="txt_num">e.</span>실제로 물품을 판매하고자 하는
                의사 없이 물품등록을 한 경우 또는 이를 알고 낙찰 받은 것으로
                인정되는 경우
              </li>
              <li>
                <span class="txt_num">f.</span>회원이 이 약관에 위배되는 행위를
                한 경우
              </li>
            </ul>
          </li>
          <li>
            <span class="txt_num">2.</span>이용계약은 회사의 해지의사가 회원에게
            도달한 때에 종료되나, 회원이 제공한 연락처 정보의 오류로 인하여
            회사가 해지의사를 통지할 수 없는 경우에는 블랙랏 웹사이트에
            해지의사를 공지하는 때에 종료됩니다. 회사가 이용계약을 해지하는
            경우, 회사는 별도의 통지 없이 해당 회원과 관련된 경매를 취소할 수
            있습니다.
          </li>
        </ul>
      </li>
      <li>
        <span class="txt_num">③</span>이용계약이 종료되는 경우, 회사는 해당
        회원과 상호 정산합니다. 정산 후, 잔액이 있는 경우 회사는 해당 회원이
        지정한 입금계좌로 그 금액을 즉시 송금하고, 그 반대의 경우에는 해당
        회원은 회사에게 그 금액을 즉시 변제하여야 합니다.
      </li>
    </ul>
    <strong id="useterms01_09" class="tit_subject"
      >제9조 (회원 개인정보의 수집과 보호)</strong
    >
    <p class="desc_g">
      회사는 이용계약 체결 과정에서 가입신청자의 이메일 주소 등의 정보를 수집할
      수 있으며, 회사는 개인정보보호법 등 관계법령이 정하는 바에 따라 회원의
      개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는
      관련법 및 회사의 개인정보보호정책이 적용됩니다.
    </p>
    <strong id="useterms01_10" class="tit_subject"
      >제10조 (회원정보의 변경)</strong
    >
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>회원은 이용 신청 시 기재한 사항이
        변경되었을 경우, 해당 사항을 수정해야 합니다. 단, 아이디(ID), 성명은
        수정할 수 없음을 원칙으로 합니다.
      </li>
      <li>
        <span class="txt_num">②</span>회원정보가 변경되었음에도 해당 사항을
        수정하지 않음으로써 발생한 각종 손해와 잘못된 수정으로 인하여 발생한
        손해는 회원이 부담하여야 합니다.
      </li>
    </ul>
    <strong id="useterms11" class="tit_subject"
      >제11조 (아이디(ID)의 관리)</strong
    >
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>아이디(ID) 및 비밀번호에 대한 관리책임은
        회원에게 있으며, 회원은 아이디(ID) 및 비밀번호를 타인에게 양도, 대여할
        수 없습니다.
      </li>
      <li>
        <span class="txt_num">②</span>회사의 행위에 의하지 아니한 아이디(ID)
        또는 비밀번호의 유출, 양도, 대여로 인한 손실 또는 손해에 대한 책임을
        회사가 부담하지 않습니다.
      </li>
      <li>
        <span class="txt_num">③</span>회원이 아이디(ID) 또는 비밀번호를 도난
        당하거나 제3자가 이를 사용하고 있음을 인지한 경우, 즉시 회사에게
        통보하여야 하고, 회사의 안내가 있는 경우에는 그에 따라야 합니다.
      </li>
    </ul>
    <strong id="useterms12" class="tit_subject">제12조 (서비스 이용)</strong>
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>회사가 제공하는 서비스의 종류와 내용은
        변경될 수 있습니다.
      </li>
      <li>
        <span class="txt_num">②</span>회사는 서비스의 종류에 따라 각 서비스의
        특성, 절차 및 구매(낙찰)방법에 대한 사항을 서비스 화면을 통하여
        공지합니다. 회원은 회사가 공지한 각 서비스 이용에 관한 사항을 사전에
        충분히 이해하고 서비스를 이용하여야 합니다.
      </li>
      <li>
        <span class="txt_num">③</span>회원은 아이디(ID)와 비밀번호를 통하여
        회사가 제공하는 서비스에 접속할 수 있고, 동 서비스를 이용하여 물품을
        판매하거나 구매할 수 있습니다. 회사가 지정하는 서비스에 대해서는
        판매자가 될 수 있는 자격요건이 설정될 수 있습니다. 회사는 이러한 이용
        제한사항을 해당 서비스화면에서 공지합니다.
      </li>
      <li>
        <span class="txt_num">④</span>회원이 판매 또는 구매하는 경우, 반드시
        회사가 마련한 매매보호서비스를 통하여 거래를 완료하여야 합니다. 회사는
        회원 간의 직거래로 인하여 발생한 문제에 대하여 개입하지 않습니다.
      </li>
    </ul>
    <strong id="useterms13" class="tit_subject"
      >제13조 (서비스 이용정지 및 회원의 신용도 관리)</strong
    >
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>회사는 서비스를 통한 거래의 안전성과
        신뢰성을 위하여 일정한 경우 경고, 일시 정지, 영구 이용 정지 등으로
        서비스 이용을 단계적으로 제한할 수 있습니다.
      </li>
      <li>
        <span class="txt_num">②</span>회사는 전항에도 불구하고, 회원의 의무 위반
        행위나 서비스 운영 방해 행위에 대하여 사안의 중대성이나 긴급성 등을
        토대로 서비스에 미치는 영향을 고려하여 경고 등의 단계적인 조치를 거치지
        않고 곧바로 일시 정지나 영구이용정지를 할 수 있습니다. 특히,
        주민등록법을 위반한 명의도용 및 결제도용, 저작권법을 위반한
        불법프로그램의 제공 및 운영방해, 정보통신망법을 위반한 불법통신 및 해킹,
        악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반하여
        서비스에 중대한 영향을 끼친 경우에는 즉시 영구이용정지를 할 수 있으며,
        영구이용정지를 할 수 있는 위반행위는 이에 제한되지 않습니다. 본 항에
        따른 영구이용정지 시 서비스 이용을 통한 혜택 등도 모두 소멸되며, 회사는
        이에 대해 별도로 보상하지 않습니다.
      </li>
      <li>
        <span class="txt_num">③</span>회원 가입 후, 6개월 이상 거래내역이 없는
        경우는 회사에서 임의로 준회원으로 회원등급을 조정할 수 있습니다.
      </li>
      <li>
        <span class="txt_num">④</span>회사는 본 조의 이용제한 범위 내에서 제한의
        조건 및 세부내용은 본 약관 또는 운영 정책 및 개별 서비스 상의
        이용약관이나 운영정책에서 정하는 바에 의합니다.
      </li>
      <li>
        <span class="txt_num">⑤</span>회원은 본 조에 따른 이용제한 등에 대해
        회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가
        정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.
      </li>
    </ul>
    <strong id="useterm14" class="tit_subject"
      >제14조 (회사의 책임 사항과 권한)</strong
    >
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>회사는 회원 상호간의 거래를 위한 온라인
        거래 장소(마켓플레이스)를 제공할 뿐이며, 물품을 판매하거나 구매하고자
        하는 회원을 대리하지 않습니다. 다만 출품자가 위탁하여 회사가 판매를
        대행하는 기획서비스 등의 경우는 전화, 이메일, 서면 등의 방식을 통해
        대리입찰이 가능하며, 이 경우 등록 IP와 입찰 IP가 동일할 수 있습니다.
      </li>
      <li>
        <span class="txt_num">②</span>회사는 경매 및 쇼핑몰 서비스를 통하여
        이루어지는 회원 간의 판매 및 구매와 관련하여 판매의사 또는 구매의사의
        존부 및 진정성, 등록물품의 품질, 완전성, 안전성, 적법성 및 타인의 권리에
        대한 비침해성, 회원이 입력하는 정보 및 그 정보를 통하여 링크된 URL에
        게재된 자료의 진실성 등에 대하여 보증하지 아니하며, 이와 관련한 위험과
        책임은 해당 회원이 부담해야 합니다.
      </li>
      <li>
        <span class="txt_num">③</span>회사는 회원이 게재하는 물품설명 등의 제반
        정보를 통제하거나 제한하지 않습니다. 다만, 회원이 게재한 정보의 내용이
        타인의 명예, 권리를 침해하거나 법규정을 위반한다고 판단하는 경우에
        회사는 이를 삭제할 수 있고, 경매취소(판매취소), 경매중지(판매중지), 기타
        필요한 조치를 취할 수 있습니다.
      </li>
      <li>
        <span class="txt_num">④</span>회사는 기획서비스의 경우 다음과 같은 책임
        사항과 권한을 갖습니다.
        <ul class="list_2depth_type2">
          <li>
            <span class="txt_num">1.</span>회사는 예정 고지한 서비스 일정을
            변경할 수 있습니다.
          </li>
          <li>
            <span class="txt_num">2.</span>해당 서비스 화면에 기재된 작가명,
            작품명, 재질, 크기, 출처 등 모든 내용은 회사의 의견일 뿐 절대적
            사실이 아닙니다.
          </li>
          <li>
            <span class="txt_num">3.</span>위탁계약이 성립된 물품에 대하여
            촬영된 물품 사진 저작권은 회사가 가지며, 회사는 이를 블랙랏 웹사이트
            게시와 관련 인쇄물 제작 및 지속적인 DB 구축 등 회사의 목적에 맞게
            사용할 수 있는 권리를 갖습니다.
          </li>
          <li>
            <span class="txt_num">4.</span>물품의 진위시비, 거래의 불법성 시비
            및 그에 준하는 사유가 발생하는 경우 회사는 위탁 계약된 물품을
            출품하지 않을 수 있고, 출품하여 구매(낙찰)된 이후에도 구매(낙찰)를
            취소할 수 있습니다. 이때 회사는 위탁자나 구매(낙찰)자에 대하여
            원상회복 이외에 손해배상 등의 책임을 지지 않습니다.
          </li>
        </ul>
      </li>
      <li>
        <span class="txt_num">⑤</span>회사는 천재지변 또는 이에 준하는 불가항력,
        정보통신설비의 보수점검, 교체 또는 고장, 통신의 두절 등으로 인하여
        일시적 또는 종국적으로 서비스를 제공할 수 없는 경우, 서비스 제공에 관한
        책임이 면제됩니다. 이 경우 회사는 본 약관에서 정한 방법으로 회원들에게
        통지합니다.
      </li>
    </ul>
    <strong id="useterm15" class="tit_subject">제15조 (매매보호서비스)</strong>
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>회사는 서비스를 제공하는 과정에서 대금의
        수령, 보관 및 송금업무로 이루어지는 매매보호서비스를 제공합니다. 이러한
        매매보호서비스는 회사의 서비스를 통하여 이루어지는 회원 상호간의 거래의
        안전성과 신뢰성을 도모하고 구매자를 보호하기 위한 목적에서 제공하는
        장치이므로 회사가 매매보호서비스를 통하여 판매자 또는 구매자를 대리,
        대행하거나 그 이행을 보조하는 것은 아닙니다.
      </li>
      <li>
        <span class="txt_num">②</span>회원이 매매보호서비스를 통하지 않는
        직거래를 유도하는 경우, 회사는 경매(판매)취소, 경매(판매)중지 등 기타
        필요한 조치를 취할 수 있습니다.
      </li>
      <li>
        <span class="txt_num">③</span>매매보호서비스의 일환으로 이루어지는
        대금보관으로 인하여 회사나 회사가 지정하는 제3의 용역회사가 취득하는
        이자 등은 서비스제공의 대가이므로 회원은 이에 대한 반환을 청구할 수
        없습니다.
      </li>
      <li>
        <span class="txt_num">④</span>회사가 제공하는 매매보호서비스를 이용하지
        않은 거래 및 물품과 관련하여 발생한 모든 상황은 판매자와 구매자가
        상호협의를 통해 해결하여야 합니다.
      </li>
    </ul>
    <strong id="useterms16" class="tit_subject"
      >제16조 (판매자 및 위탁자의 의무)</strong
    >
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>판매자는 이 약관과 회사가 서비스 화면을
        통하여 미리 공지하는 절차에 따라 판매물품의 등록, 경매 진행 과정의 관리,
        낙찰자, 구매자에 대한 거래이행, 물품배송 등의 사후처리를 수행하여야
        합니다.
      </li>
      <li>
        <span class="txt_num">②</span>판매자는 입찰 및 구매회원의 질문에 성실히
        응해야 합니다.
      </li>
      <li>
        <span class="txt_num">③</span>판매자는 입찰자의 입찰행위, 구매자의
        구매행위가 허위 또는 부당한 경우에 그 행위의 취소를 관리자에게 요청할 수
        있습니다.
      </li>
      <li>
        <span class="txt_num">④</span>판매물품의 등록은 물품등록 서비스 화면상의
        등록양식에 따라야 하며, 물품등록 양식에 어긋난 등록이나 허위등록 등에
        대해서는 회사는 해당 물품을 삭제, 취소하거나 중개를 하지 않을 수 있으며,
        이에 따른 모든 책임은 그러한 물품을 등록한 회원이 부담하여야 합니다.
      </li>
      <li>
        <span class="txt_num">⑤</span>판매자는 직거래를 유도하는 문구나 구매자의
        정당한 권리(구매거부 등)를 제한하거나, 허위, 또는 기타 위법한 내용(문구,
        사진 또는 설명을 포함)을 등록해서는 안 되며, 스스로 또는 다른 회원을
        통하여 고의로 입찰가를 조작하는 행위는 불가합니다. 이를 위반한 경우,
        회사는 관련 회원이나 해당 경매에 대하여 경매취소, 경매중지, 게시글
        비공개 및 기타 필요한 조치를 취할 수 있습니다.
      </li>
      <li>
        <span class="txt_num">⑥</span>회사가 안전성과 신뢰성을 증진시키기 위하여
        시행하는 이미지 감식작업의 결과에 따라서 서비스 물품의 카테고리
        임의변경과 판매중지 및 노출을 임의로 제한할 수 있으며, 판매자는 이에
        적극 협조하여야 합니다.
      </li>
      <li>
        <span class="txt_num">⑦</span>판매자는 회사에 서비스를 위탁할 수
        있습니다. 판매자가 서비스를 위탁하는 경우 다음 규정이 적용됩니다.
        <ul class="list_2depth_type2">
          <li>
            <span class="txt_num">1.</span>다음의 경우 회사는 그 접수를 제한할
            수 있습니다.
            <ul class="list_3depth_type">
              <li>
                <span class="txt_num">a.</span>작품의 진위여부에 관하여 시비의
                우려가 있는 경우
              </li>
              <li>
                <span class="txt_num">b.</span>서비스의 특성에 맞지 않거나
                서비스 물품으로 적합하지 않은 경우
              </li>
              <li>
                <span class="txt_num">c.</span>위탁자와 회사 간에 서비스 물품에
                대한 시작가를 합의하지 못하는 경우
              </li>
            </ul>
          </li>
          <li>
            <span class="txt_num">2.</span>위탁자는 물품위탁 시에 출품에 관련된
            기본 경비(홍보비, 도록 등재비, 일반 감정료 등)에 대한 부담이 있을 수
            있고, 위탁자는 이외에 특별 감정이 필요하다고 인정되거나 물품의
            수리가 필요할 경우, 감정실비와 수리 실비를 지불하여야만 합니다. 이와
            관련해 유찰이 된 경우에도 실비는 위탁자에게 반환되지 않습니다.
          </li>
          <li>
            <span class="txt_num">3.</span>위탁자에게는 다음의 의무와 책임이
            있습니다.
            <ul class="list_3depth_type">
              <li>
                <span class="txt_num">a.</span>위탁자는 자신이 위탁하는 서비스
                물품에 대하여 처분권한이 있어야 합니다.
              </li>
              <li>
                <span class="txt_num">b.</span>위탁자는 위탁물품에 대하여
                제3자가 유치권, 질권 등 기타 어떠한 권리도 가지지 아니함을
                보증할 의무가 있습니다.
              </li>
              <li>
                <span class="txt_num">c.</span>위탁자는 판매(낙찰)와 동시에
                위탁물품의 소유권이 구매자에게 이전됨을 보증할 책임이 있습니다.
              </li>
              <li>
                <span class="txt_num">d.</span>위탁물품을 회사에서 취급하지
                않기로 하는 경우, 회사는 이 사실을 위탁자에 통보 하고 위탁자는
                그 통보를 받은 후 7일 안에 물품을 인수해야 합니다. 위탁자는 인수
                지연 중에 발생하는 물품의 훼손, 분실 등에 대하여 회사에게 책임을
                물을 수 없으며, 회사의 통보 후 7일 경과 후에는 회사 규정의
                보관료가 징수됩니다.
              </li>
              <li>
                <span class="txt_num">e.</span>위탁자는 위탁물품이
                문화재보호법에 저촉되지 아니함을 보증하여야 합니다. 위탁물품이
                매장문화재, 도굴품, 장물 등 불법적인 물품임이 드러나는 경우 그
                민형사상 책임은 위탁자에게 있으며, 위탁자는 위탁대금 전액을 즉시
                환급하여야 함은 물론 그로 인하여 회사가 입는 손해 일체를
                배상하여야 합니다.
              </li>
            </ul>
          </li>
          <li>
            <span class="txt_num">4.</span>위탁계약이 성립된 이후에는 위탁자는
            위탁을 임의로 철회할 수 없으며, 부득이 철회를 하는 경우에는 페널티가
            부과됩니다. 페널티는 경매의 경우 경매시작가의 10%, 바이나우의 경우
            판매금액의 10%가 부과됩니다. 또한 위탁자의 위탁 철회로 손해가 발생할
            경우 회사는 위 위약벌 약정과 별개로 손해배상을 청구할 수 있습니다.
          </li>
          <li>
            <span class="txt_num">5.</span>위탁자는 판매되지 않은(유찰) 물품을
            서비스종료일을 포함하여 7일 이내에 인수하여야 하고, 7일 경과 후에
            발생된 손상에 대한 책임은 회사가 부담하지 않으며, 회사 규정의
            보관료를 납부하여야 합니다.
          </li>
          <li>
            <span class="txt_num">6.</span>위탁자는 스스로 또는 다른 회원을
            통하여 고의로 입찰가를 조작하는 행위를 금합니다. 이를 위반한 경우,
            회사는 관련 회원이나 해당 서비스에 대하여 판매취소, 판매중지, 게시글
            비공개 및 기타 필요한 조치를 취할 수 있습니다.
          </li>
        </ul>
      </li>
    </ul>
    <strong id="useterms17" class="tit_subject"
      >제17조 (판매물품의 등록)</strong
    >
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>판매자는 판매물품의 등록 시 다음과 같은
        사항을 정확하게 기재하여야 합니다.
        <ul class="list_2depth_type2">
          <li>
            <span class="txt_num">1.</span>물품의 상세정보: 물품에 대한
            상세정보는 사실대로 기재하여야 합니다. 허위 또는 과장 정보를 기재한
            경우, 모순되는 내용의 정보가 기재되어 있는 경우, 회사는 해당 판매를
            취소하거나 중지시킬 수 있으며, 해당 판매자 회원은 불공정 경매를
            조장한 것이 되어 회원자격이 제한될 수 있습니다. 이 경우 취소 또는
            중지된 경매에 관한 등록수수료 및 유료부가서비스 수수료는 환불되지
            않으며, 경매의 취소 또는 중지로 인한 모든 위험과 책임은 판매물품을
            등록한 해당 회원이 부담해야 하고 구매자로부터는 정당한 반품의 원인이
            됩니다. 경매가 취소되거나 중지되면, 회사는 해당 판매자와 입찰자에게
            그러한 사실을 통지합니다.
          </li>
          <li>
            <span class="txt_num">2.</span>판매금액: 등록물품의 판매하고자 하는
            금액을 기재해야 합니다. 경매의 경매 시작가를, 바이나우의 경우
            즉시판매가능금액을 기재해야 합니다.
          </li>
          <li>
            <span class="txt_num">3.</span>판매 가능지역: 판매 가능지역은 배송
            가능한 지역을 선택해야 합니다.
          </li>
          <li>
            <span class="txt_num">4.</span>결제정보: 판매자는 구매자가 결제 시
            사용할 수 있는 결제 정보를 명확히 기재해야 합니다.
          </li>
          <li>
            <span class="txt_num">5.</span>운송료: 운송료는 판매자 또는 구매자
            부담 2가지 중 하나를 선택하여야 합니다. 운송료가 구매자 부담일 경우
            배송방법과 배송비를 기재하여야 합니다. 회사에서는 운송료 부분(발송,
            반송)에 대해 책임이 없습니다.
          </li>
          <li>
            <span class="txt_num">6.</span>판매자는 등록물품에 특별한 거래조건이
            있거나 추가되는 비용이 있는 경우, 이를 구매자가 알 수 있도록
            명확하게 기재하여야 합니다. 다만, 이러한 특별한 거래조건은
            전자상거래 등에서의 소비자 보호에 관한 법률 혹은 회사가 정한
            거래조건에 기반해야 합니다.
          </li>
        </ul>
      </li>
      <li>
        <span class="txt_num">②</span>부가서비스의 이용: 판매자는 물품등록 시
        등록물품에 대한 광고의 효과를 높이기 위하여 회사가 제공하는 부가서비스를
        이용할 수 있습니다. 부가서비스의 내용과 이용절차는 별도의 서비스 화면에
        게재합니다. 부가서비스의 종류나 내용, 이용료는 변경될 수 있습니다.
      </li>
      <li>
        <span class="txt_num">③</span>이미지 저작권
        <ul class="list_2depth_type2">
          <li>
            <span class="txt_num">1.</span>회원은 자신의 게시물이 타인의
            저작권이나 명예 등 제3자의 권리를 침해하지 않음을 보증합니다. 만약
            제3자가 이를 이유로 회사에 문제를 제기하는 경우 이로 인한 회사의
            손해를 모두 배상할 의무가 있습니다.
          </li>
          <li>
            <span class="txt_num">2.</span>회사는 전항에 따른 권리자의 요청이
            없는 경우라도 전자상거래법, 정보통신망법, 저작권법, 정보통신에 관한
            심의 규정 등 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및
            관련법에 위반되는 경우에는 관련법에 따라 해당 게시물에 대해 임시조치
            등을 취할 수 있습니다.
          </li>
          <li>
            <span class="txt_num">3.</span>회사는 판매자가 등록한 상품의 원활한
            거래를 위하여 SNS(인스타그램, 페이스북, 트위터 등), 블로그, 커뮤니티
            사이트 등 다양한 홍보 채널에 상품을 노출할 수 있습니다. 다만, 회원은
            상품의 노출을 원치 않는 경우 회사에 홍보 채널에서의 노출 제외를
            요청할 수 있습니다.
          </li>
          <li>
            <span class="txt_num">4.</span>판매자가 등록한 이미지를 회사 이외의
            개인 혹은 단체가 도용할 경우 회사는 이에 대해 책임이 없으며, 도용한
            당사자가 그에 따른 불이익을 감수합니다.
          </li>
        </ul>
      </li>
    </ul>
    <strong id="useterm18" class="tit_subject">제18조 (매매 부적합물품)</strong>
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>회사는 매매불가물품 및 매매제한물품(이하
        통칭하여 ‘매매부적합물품’)을 회원이 알 수 있도록 공지사항으로
        고지합니다. 회사는 등록된 물품이 온라인상에서의 매매, 유통에 적합하지
        않은 물품인 경우에는 해당 경매 및 판매행위를 삭제, 취소하거나 중지할 수
        있습니다.
      </li>
      <li>
        <span class="txt_num">②</span>매매 부적합 물품의 등록을 이유로 경매 및
        판매행위가 삭제, 취소되거나 중지된 경우, 그 등록에 사용된 등록수수료 및
        유료부가서비스 수수료는 환불되지 않으며, 판매자는 회원자격이 제한될 수
        있습니다.
      </li>
      <li>
        <span class="txt_num">③</span>매매 부적합 물품임을 알거나 알 수 있는
        상태에서 물품을 구매한 경우에는 매매대금의 입출금을 중개하지 않을 수
        있습니다.
      </li>
      <li>
        <span class="txt_num">④</span>카테고리별 부적합물로 판단될 시 회사의
        직권으로 카테고리 이동 또는 삭제할 수 있습니다. 해당 기준에 따른 내용은
        고객센터, 공지사항 또는 상품페이지 등 서비스 화면을 통하여 게재합니다.
        회사는 매매 부적합 물품에 해당되는 물품의 구체적인 종류와 내용을 별도의
        서비스화면에 게재할 수 있으며, 이를 임의로 추가, 변경할 수도 있습니다.
      </li>
    </ul>
    <strong id="useterm19" class="tit_subject">제19조 (물품 판매규정)</strong>
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>경매는 물품 등록 후 판매자가 기재한
        판매기간까지 진행됩니다. 다만, 구매자가 즉시구매가로 구매한 경우 조기에
        종료될 수 있습니다.
      </li>
      <li>
        <span class="txt_num">②</span>판매가 종료되고 주문이 완료되면, 회사는
        마이페이지의 판매관리 화면에 낙찰 여부를 게시하며 판매자는 이를 확인할
        수 있습니다.
      </li>
      <li>
        <span class="txt_num">③</span>회사는 구매자가 물품대금을 결제하면 즉시
        판매자에게 입금확인 및 물품발송 통지를 보냅니다.
      </li>
      <li>
        <span class="txt_num">④</span>판매자는 회사로부터 구매자의 입금 또는
        대금결제에 대한 확인통지를 받은 후에는 이 약관에서 정하고 있는
        배송절차를 취하여야 합니다.
      </li>
      <li>
        <span class="txt_num">⑤</span>사는 물품대금의 입출금에 관한
        매매보호서비스만을 제공합니다. 판매자는 그 외 물품의 배송, 반품, A/S
        등에 관련한 사항을 항상 구매자와 협의하여 진행해야 하며, 회사는 판매자와
        구매자간의 거래에 관여하지 않음을 원칙으로 합니다.
      </li>
    </ul>
    <strong id="useterms20" class="tit_subject">제20조 (물품 구매규정)</strong>
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>물품을 구매하고자 하는 회원은 회사가
        제공하는 서비스 화면에서 응찰 및 구매하여야 합니다. 구매에 대해서는
        수수료가 부과되지 않습니다. 만 19세 미만의 회원은 청소년 보호법에서
        금지하는 물품에 관한 응찰이 금지됩니다.
      </li>
      <li>
        <span class="txt_num">②</span>입찰, 주문, 구매신청 방법
        <ul class="list_2depth_type2">
          <li>
            <span class="txt_num">1.</span>회원은 물품 등록정보를 자세히 살펴본
            후, 구매의사가 있을 때에 한하여 신중히 응찰하여야 합니다. 전시를
            동반한 경우 입찰자는 물품의 훼손 및 복구 여부, 작품 이미지와 실물의
            차이 등에 대해 확인한 후 자신의 판단과 책임 하에 서비스에 참여해야
            합니다. 응찰자의 미확인으로 인한 위험부담은 입찰자(구매자)가 지게
            됩니다.
          </li>
          <li>
            <span class="txt_num">2.</span>응찰은 지정된 경매 마감일시까지 여러
            차례 할 수 있으나, 같은 경매가로 연속적인 중복입찰은 허용되지
            않습니다.
          </li>
          <li>
            <span class="txt_num">3.</span>응찰자가 이용제한 상태이거나 판매자와
            인적정보 동일인 등으로 판단이 되어 경매의 공정성 내지 원활한
            경매진행이 어렵다고 판단될 때는 해당 입찰자의 입찰내역을 삭제할 수
            있습니다. 입찰 중인 회원의 개인정보가 미흡하거나 신뢰할 수 없을
            때에는 회사에서 개인정보내지 신용도의 확인절차를 가질 수 있으며,
            즉시 확인이 불가할 경우 입찰을 제한할 수 있습니다.
          </li>
          <li>
            <span class="txt_num">4.</span>경매 시작가가 1,000만원 이상인
            프리미엄랏을 응찰하기 위해서는 경매 시작가의 10%를 응찰 전 예치해야
            합니다. 최종 낙찰자는 낙찰이 확정되는 순간 구매대금의 10%가
            예치금으로 자동 결제되고, 낙찰을 받지 못한 응찰자들의 예치금은 경매
            종료 후 즉시 환불됩니다. 10% 금액의 예치는 카드 혹은 계좌이체 등으로
            가능합니다.
          </li>
        </ul>
      </li>
      <li>
        <span class="txt_num">③</span> 구매과정
        <ul class="list_2depth_type2">
          <li>
            <span class="txt_num">1.</span>경매종료 후, 회사가 즉시 발송하는
            낙찰(주문)확인메일 또는 마이페이지 화면에서 낙찰여부(구매 여부)를
            확인할 수 있습니다.
          </li>
          <li>
            <span class="txt_num">2.</span>경매 시작가와 즉시구매가가 동시에
            설정된 경매는 경매기간 만료 이전에 즉시구매가로 입찰하면 낙찰이
            이루어지고, 거래가 성사됩니다. 단, 즉시구매는 경매 마감 24시간
            전까지 가능하며, 즉시구매 직후 전액 결제가 이루어진 경우에 한해 그
            효력이 발생합니다. 결제가 이뤄지지 않는 경우 잔여 시간 동안 경매가
            계속됩니다.
          </li>
          <li>
            <span class="txt_num">3.</span>구매자는 별도의 기재가 없는 한
            경매종료시로부터 72시간 이내에 물품대금을 회사가 지정하는 계좌로
            입금하여야 합니다. 경매종료시로부터 72시간 이내에 입금이 확인되지
            않는 경우에 미입금으로 간주되어 해당 경매는 취소됩니다.
          </li>
          <li>
            <span class="txt_num">4.</span>구매자는 회사가 제공하는 서비스
            화면에 주문사항, 물품 수령지 정보를 정확히 기재하여야 하며, 미기재
            또는 오기로 인한 불이익은 구매자가 부담합니다.
          </li>
          <li>
            <span class="txt_num">5.</span>회사는 안전성과 신뢰성을 증진시키기
            위하여 시행하는 이미지 감식작업의 결과에 따라서 서비스 상품의
            카테고리 변경과 그 경매 및 판매중지(취소)를 할 수 있으며, 구매자
            회원들은 이에 적극 협조를 하여야 합니다.
          </li>
          <li>
            <span class="txt_num">6.</span>구매자는 직거래 문구, 판매방해 및
            인신 공격성 문구와 그 외 회사 약관에 반하는 문구를 등록해서는 안
            됩니다. 이를 위반한 경우, 회사는 해당 게시글 비공개 및 기타 필요한
            조치를 취할 수 있습니다.
          </li>
        </ul>
      </li>
    </ul>
    <strong id="useterms21" class="tit_subject">제21조 (대금결제 방법)</strong>
    <p class="desc_g">
      구매자는 경매종료시로부터 72시간 이내에 신용카드, 가상계좌, 폰뱅킹,
      인터넷뱅킹 등의 각종 계좌이체(간편결제), 회사 은행 계정 송금 등으로 물품
      대금을 지급할 수 있습니다.
    </p>
    <strong id="useterm22" class="tit_subject">제22조 (배송)</strong>
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>판매자는 회사로부터 구매자의 대금결제에
        대한 확인 통지를 받은 날을 포함하여 3일 이내에 배송에 필요한 조치를
        하고, 마이페이지>판매관리>배송요청화면에 물품을 배송하였음을 배송일자와
        함께 표시하여야 합니다. 입금 확인일을 포함하여 3일이 경과하도록
        배송표시가 이루어지지 않을 경우 회사는 향후 판매 제한 등의 불이익을 줄
        수 있고, 구매자 취소 요청 시 해당 주문건은 판매회원의 승인 없이 환불처리
        됩니다.
      </li>
      <li>
        <span class="txt_num">②</span>판매자가 물품을 배송하였음을 표시한 경우,
        회사는 구매자에게 즉시 물품 발송 확인통지를 보냅니다. 구매자는 판매자의
        발송 여부를 물품 발송 확인메일이나 마이페이지>구매관리>구매결정대기에서
        확인할 수 있습니다.
      </li>
      <li>
        <span class="txt_num">③</span>구매자의 귀책사유 없이, 판매자의 배송지
        기재 오류 등으로 인하여 구매자가 상품을 정상적으로 수령하지 못한 경우
        또는 상품의 배송과정에서 하자가 발생한 경우, 판매자는 그에 관한 모든
        책임을 부담하여야 합니다. 따라서 판매자는 구매자가 하자(파손, 멸실, 부패
        등)없는 물품을 수령할 수 있도록 물품의 포장, 운송업체의 선정, 기타
        필요한 조치를 적정하게 하여야 합니다.
      </li>
      <li>
        <span class="txt_num">④</span>경매 서비스를 통하여 거래된 물품의 운송과
        관련하여 판매자와 구매자, 운송업체, 금융기관, 매매보호서비스 운영업체
        등과 사이에 분쟁 등이 발생한 경우에는 관련 당사자간에 해결하여야 합니다.
        회사는 이에 대하여 관여하지 않습니다.
      </li>
      <li>
        <span class="txt_num">⑤</span>구매자는 판매자 또는 회사가 판매물품별로
        직접 수령 등으로 배송방법을 지정한 경우 이에 따라야 합니다.
      </li>
    </ul>
    <strong id="useterm23" class="tit_subject">제23조 (구매확정)</strong>
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>구매자는 물품을 수령한 후 구매를 최종
        결정하면, 물품수령일 포함 5일 이내(이하 ‘구매확정기간’이라고 합니다)에
        판매자에 대한 대금송금을 위하여 마이페이지>구매관리>구매결정대기에서
        수령확인 항목을 클릭해야 합니다(이하 ‘구매 확정’이라고 합니다)
      </li>
      <li>
        <span class="txt_num">②</span>구매자가 구매확정기간이 경과하도록
        구매자가 회사에 배송사고가 있음을 신고하지 않은 경우, 구매자는
        정상적으로 물품을 수령하였으며, 구매의사도 있는 것으로 간주함에 동의한
        것으로 봅니다(이하 ‘자동구매확정’이라고 합니다).
      </li>
      <li>
        <span class="txt_num">③</span>위 제1항 및 제2항의 경우, 회사는
        물품대금에서 이 약관 제25조(수수료 및 페널티)에 따른 수수료 및 기타
        회사에 대한 미납 이용수수료 일체를 공제한 나머지 금액을 판매자에게
        송금합니다.
      </li>
      <li>
        <span class="txt_num">④</span>판매자는 판매대금 수령시에 송금방법을
        지정하여야 합니다. 계좌입금을 송금방법으로 선택한 경우 판매자가 지정한
        입금계좌의 예금주는 판매자와 동일인임을 원칙으로 합니다. 판매자가 지정한
        입금계좌의 예금주와 판매자가 상이한 경우 회사는 이용계약 및 해당 거래의
        진정성을 확인하기 위하여 최고 60일까지 물품대금의 송금을 보류할 수
        있습니다.
      </li>
      <li>
        <span class="txt_num">⑤</span>위 1항 및 제2항의 경우 구매자는 원칙적으로
        상품의 미수령, 반품 등의 사유로 회사에 이의를 제기할 수 없으며,
        물품구매대금의 환불, 보상 등의 모든 문제는 판매자와 직접 해결하여야
        합니다.
      </li>
    </ul>
    <strong id="useterms24" class="tit_subject">제24조 (환불 및 반품)</strong>
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>계약의 취소(환불 및 반품)는 구매자가
        판매자와 직접 해결해야 함이 원칙입니다. 다만 회사는 구매자가 다음 각호의
        요건을 모두 충족하는 경우에 한하여 계약의 취소에 관한 필요한 협의를
        주선할 수 있습니다.
        <ul class="list_2depth_type2">
          <li>
            <span class="txt_num">1.</span>매매보호서비스를 이용하여 거래한
            경우로서, 물품수령일 포함 구매확정기간 종료 전(물품수령일 포함 5일
            이전)에 환불 및 반품신청이 있는 경우
          </li>
          <li>
            <span class="txt_num">2.</span>판매자의 귀책사유가 명백한
            경우('상품'의 명백한 결함 또는 하자, 도착한 '상품'이 상세정보와
            현저히 불일치, '상품'이 도착하지 않고 있음 등)
          </li>
        </ul>
      </li>
      <li>
        <span class="txt_num">②</span>제1항의 경우에도 다음의 경우에는 계약의
        취소가 불가능합니다.
        <ul class="list_2depth_type2">
          <li>
            <span class="txt_num">1.</span>구매자의 귀책사유로 상품이 훼손된
            경우
          </li>
          <li>
            <span class="txt_num">2.</span>사용이나 일부 소비로 인해 상품의
            가치가 현저히 감소한 경우
          </li>
          <li><span class="txt_num">3.</span>상품의 포장을 훼손한 경우</li>
          <li>
            <span class="txt_num">4.</span>구매자의 주문에 따라 개별적으로
            생산되는 상품 등으로서 상품 판매 시 반품이나 교환의 제한을
            명시적으로 고지하고 구매회원의 동의를 받은 경우
          </li>
          <li>
            <span class="txt_num">5.</span>주문 확인 후 제작에 들어가는 상품
          </li>
          <li><span class="txt_num">6.</span>매매부적합 물품</li>
          <li>
            <span class="txt_num">7.</span>기타 환불 및 반품이 불가능하다고
            회사가 판단하는 경우
          </li>
        </ul>
      </li>
      <li>
        <span class="txt_num">③</span>환불 및 반품에 대한 분쟁 신고가 반복적으로
        발생하는 '회원'의 경우, '회사'의 합리적인 판단에 따라 '상품' 삭제,
        거래취소, 계정제재 등의 조치를 취할 수 있습니다.
      </li>
      <li>
        <span class="txt_num">④</span>판매자가 통신판매업자인 경우 판매자는
        자신의 책임 하에 전자상거래의 소비자보호에 관한 법률 등 관련 법령을
        준수하여 구매자에게 환불 및 반품의 업무를 수행하여야 합니다.
      </li>
    </ul>
    <strong id="useterms25" class="tit_subject"
      >제25조 (수수료 및 페널티)</strong
    >
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>
        회사가 부과하는 수수료는 물품의 광고효과, 회사가 제공하는 시스템 이용 및
        매매보호서비스의 대가로 회사는 회원에게 각 서비스 이용에 대하여
        판매수수료, 상품 등록 수수료, 결제시스템 이용료 등의 수수료를 부과할 수
        있습니다. 회사는 각 서비스 및 서비스 수수료의 구체적인 내용을 본 약관 및
        해당 블랙랏 웹페이지에 별도로 게재합니다.
        <ul class="list_2depth_type2">
          <li><span class="txt_num">1.</span>판매수수료</li>
          <li>
            <span class="txt_num"></span>회원이 회사가 제공하는 시스템을 통하여
            상품 판매를 완료한 경우 경매는 판매자에게 낙찰가의 10%의
            판매수수료(부가세 별도), 즉시구매는 판매자에게 판매가의
            15%(부가세별도)의 판매수수료를 부과합니다. 판매자의 판매수수료는
            물품대금의 송금 시 공제됩니다.
          </li>
          <li><span class="txt_num">2.</span>상품 등록 수수료</li>
          <li>
            <span class="txt_num"></span>판매자가 월 일정 수량 이상의 상품을
            등록할 경우 부과되는 수수료로, 상품의 판매 여부와 상관없이
            부과됩니다. 상품 등록 수에 따른 비용은 판매자 가입 화면에 별도
            표시합니다.
          </li>
          <li><span class="txt_num">3.</span>결제 시스템 이용료</li>
          <li>
            <span class="txt_num"></span>카드 결제에 따라 PG사에 지급하는
            수수료로 일괄 % 책정되며 판매자에게 부과됩니다.
          </li>
        </ul>
      </li>
      <li>
        <span class="txt_num">②</span>건전한 거래를 위하여 회사는 다음 각호의
        경우 판매자와 구매자에게 판매 및 응찰 제한을 포함한 제13조에서 규정한
        내용의 페널티를 부과합니다.
        <ul class="list_2depth_type2">
          <li>
            <span class="txt_num">1.</span>판매자에게 페널티가 부과되는 경우
            <ul class="list_3depth_type">
              <li><span class="txt_num">a.</span>판매취소 또는 위탁취소</li>
              <li>
                <span class="txt_num">b.</span>발송지연(대금결제에 대한 확인
                통지를 받은 날을 포함하여 3 영업일 이내에 배송절차 미조치)
              </li>
              <li>
                <span class="txt_num">c.</span>상품에 명백한 하자가 존재하거나,
                도착한 상품이 상세정보와 현저히 불일치하는 경우
              </li>
            </ul>
          </li>
          <li>
            <span class="txt_num">2.</span>구매자에게 페널티가 부과되는 경우,
            <ul class="list_3depth_type">
              <li><span class="txt_num">a.</span>구매 취소</li>
              <li>
                <span class="txt_num">b.</span>입금지연(경매종료시로부터 72시간
                이내에 구매대금 미결제)
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <span class="txt_num">③</span>회사는 필요한 경우 서비스 수수료를 신설,
        변경할 수 있으며, 신설 또는 변경사항은 블랙랏 웹사이트에 공지합니다.
        변경된 수수료는 공지된 후, 7일이 경과한 날부터 등록되는 물품에 대하여
        적용됩니다.
      </li>
    </ul>
    <strong id="useterm26" class="tit_subject">제26조 (회사의 면책)</strong>
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>회사가 회원들에게 제공하는 서비스는 온라인
        거래 장소(마켓플레이스)와 기타 부가정보 제공에 그칩니다. 경매 서비스를
        통하여 이루어지는 회원 상호간의 거래와 관련된 경매 진행의 관리, 낙찰자에
        대한 거래이행, 물품배송, 청약철회 또는 반품, 물품하자로 인한 분쟁해결 등
        필요한 사후처리는 거래당사자인 회원들이 직접 수행하여야 합니다. 회사는
        이에 대하여 관여하지 않으며, 어떠한 책임도 부담하지 않습니다. 다만,
        온라인 거래 장소의 안전과 신뢰도 증진 그리고 공정한 경매를 위한 도구를
        제공하고 이를 보장하기 위한 사이트관리를 할 수 있을 뿐입니다.
      </li>
      <li>
        <span class="txt_num">②</span>회사는 천재지변 또는 이에 준하는 불가항력,
        정보통신설비의 보수점검, 교체 또는 고장, 통신의 두절 등으로 인하여
        일시적 또는 종국적으로 서비스를 제공할 수 없는 경우, 서비스 제공에 관한
        책임이 면제됩니다.
      </li>
      <li>
        <span class="txt_num">③</span>회사는 인터넷 이용자 또는 회원의
        귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.
      </li>
      <li>
        <span class="txt_num">④</span>회사는 경매서비스를 통하여 거래되는 물품의
        하자, 물품등록정보의 오류, 미비 등으로 인하여 회원이 입는 손해에
        대해서는 책임(제조물책임 포함)을 지지 않습니다.
      </li>
      <li>
        <span class="txt_num">⑤</span>회사는 회원이 다른 회원이 게재한 정보,
        자료, 사실의 정확성 등을 신뢰함으로써 입은 손해에 대하여 책임을 지지
        않습니다.
      </li>
      <li>
        <span class="txt_num">⑥</span>회사와 판매자는 독자적으로 운영되며,
        회사는 판매자와 회원 간에 이루어진 거래에 대하여는 책임을 지지 않습니다.
        특히 판매자가 통신판매업자인 경우, 판매자는 자신의 책임 하에 전자상거래
        등에서의 소비자보호에 관한 법률 등 관련 법령을 준수하여 운영하여야 하며,
        운영과 관련된 일체의 법적, 사실적 책임은 판매자가 부담합니다.
      </li>
    </ul>
    <strong id="useterms27" class="tit_subject"
      >제27조 (준거법 및 관할법원)</strong
    >
    <p class="desc_g">
      이 약관과 회사와 회원 간의 서비스이용계약, 회원 상호간의 물품거래에
      대해서는 대한민국 법령이 적용되며, 회사와 회원 간에 발생한 분쟁에 대해
      소송이 제기될 경우, 그 관할법원은 회사와 회원 간의 상호 협의 하에
      결정합니다.
    </p>
  </div>
</template>

<script>
export default {
  name: 'TermsContent',
};
</script>

<style scoped lang="scss">
.terms-area {
  max-height: 400px;
  overflow-y: auto;
}
.tit_policy {
  margin-top: 55px;
  border-top: 1px solid #e1e1e1;
  &:first-child {
    margin-top: 0;
    border-top: 0 none;
  }
}

.cont_policy .desc_g {
  padding-top: 28px;
  line-height: 26px;
}

.tit_subject {
  display: block;
  padding-top: 10px;
  line-height: 28px;

  &.fst {
    padding-top: 53px;
  }
  + .desc_g {
    padding-top: 18px;
  }
}

.list_1depth {
  padding-left: 25px;

  .txt_num {
    margin: 0 12px 0 -25px;
  }
}

.list_1depth_type1 {
  margin-top: 25px;
  padding: 24px 0 0 39px;
  border-top: 1px solid #e6e6e6;

  &.fst {
    margin-top: 0;
    padding-top: 8px;
    border-top: 0 none;
  }

  li {
    font-size: 14px;

    &:before {
      display: inline-block;
      width: 2px;
      height: 2px;
      margin: 12px 14px 0 -16px;
      vertical-align: top;
      background-color: #1e1e1e;
      content: '';
    }
  }
}

.list_2depth_type2 {
  padding: 10px 0 0 40px;
  li {
    font-size: 14px;
  }
  .txt_num {
    display: inline-block;
    min-width: 15px;
    margin: 0 4px 0 -19px;
    vertical-align: top;
  }
}

.list_1depth .list_2depth_type2 {
  padding-left: 19px;
  li {
    padding-top: 0;
  }
}

.list_2depth_type3 {
  padding: 10px 0 0 19px;
  li {
    padding-top: 0;
    font-size: 14px;
    line-height: 26px;
    list-style: decimal;
  }
}

.list_3depth_type,
.list_4depth_type {
  padding-left: 19px;
}

.list_3depth_type li {
  font-size: 13px;
}
</style>
