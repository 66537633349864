
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CurrentStep',
  props: {
    step: Number, // 1부터 시작
    isActive: Boolean,
  },
  data() {
    return {
      stepList: [
        {
          name: '사업자인증',
          description: '사업자 인증을 하고 약관에 동의해주세요.',
        },
        {
          name: '정보입력',
          description: '파트너 정보를 입력해주세요.',
        },
        {
          name: '가입완료',
          description: '가입절차가 완료되었습니다.',
        },
      ],
    };
  },
});
