
import Container from '@/components/Container.vue';
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Step1 from './Step_1.vue';
import Step2 from './Step_2.vue';
import Step3 from './Step_3.vue';

import SignUpStepItem from './SignUpStepItem.vue';
import { mount } from '@vue/test-utils';
import partnerAPI from '@/service/partnerAPI';
import getServerErrorMessage from '@/utils/getServerErrorMessage';

/**
 * 파트너가입
 */
export default defineComponent({
  name: 'SignUp',
  props: {},
  components: {
    Container,
    Step1,
    Step2,
    Step3,
    SignUpStepItem,
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const step = ref<string | undefined>('1');

    const updateCurrentStep = (nextStep) => {
      step.value = nextStep.toString();
      router.push(`/auth/signup/${nextStep}`);
    };

    // 사업자 정보
    const bizInfo = ref<any>(null);
    // const bizInfo = ref({}); // FIXME: 초기값은 falsey한 값이어야 step2, 3의 가드가 동작한다.

    const updateBizInfo = async (newBizInfo) => {
      console.log('call updateBizInfo');

      try {
        const { data } = await partnerAPI.partnerAuth.companyCheckUsingPOST1({
          param: {
            businessNo1: newBizInfo.businessNo1,
            businessNo2: newBizInfo.businessNo2,
            businessNo3: newBizInfo.businessNo3,
          },
        });
        console.log(data);
        const message = (data as any).message;
        const success = (data as any).success;
        const responseData = (data as any).data;
        alert(message);
        if (success) {
          bizInfo.value = {
            businessNumber: `${newBizInfo.businessNo1}-${newBizInfo.businessNo2}-${newBizInfo.businessNo3}`,
            ceoName: responseData.ceoName,
            companyName: responseData.companyName,
          }; // (data as any).data;
          return true;
        } else {
          return false;
        }
      } catch (e) {
        alert(getServerErrorMessage(e));
        return false;
      }
    };

    mount(() => {
      updateCurrentStep(route.params.step);
    });

    return {
      step,
      router,
      updateCurrentStep,
      bizInfo,
      updateBizInfo,
    };
  },
});
