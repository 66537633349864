<template>
  <div class="bg-black min-h-screen text-white pt-20 text-center">
    <Container :width="1200" :style="{ padding: 0, background: 'inherit' }">
      <div class="" :style="{ fontSize: '2rem' }">
        블랙랏의 파트너가 되신 것을 환영합니다.
      </div>
      <p class="mt-9 mb-16">
        파트너 사업자의 정보와 판매 물품의 적합성, 제출 서류 등을 고려해 블랙랏의 운영방침과 부합하는 셀러에 한해<br>
        파트너 가입을 허용하고 있습니다. 보통 2영업일 이내에 사용 승인을 해드리며,<br>
        부적합한 셀러의 파트너 가입은 거절될 수 있습니다.<br><br>

        문의 : 파트너센터 02-2075-4385 평일 9시~18시 (12~13시 점심시간 제외, 토/일/공휴일 휴무)
      </p>
      <div>
        <a
            class="
            flex
            w-60
            h-16
            items-center
            justify-between
            mx-auto
            text-blue-sky
            border border-blue-sky
            py-5
            px-6
            mt-10
          "
            href="https://blacklot.com/"
            target="_blank"
        >
          <span>blacklot.com 가기</span>
          <StartArrowIcon></StartArrowIcon>
        </a>
      </div>
    </Container>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType, onMounted } from 'vue';
import StartArrowIcon from './assets/StartArrowIcon.vue';
import Container from '@/components/Container.vue';
import router from '@/router';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';

export default defineComponent({
  name: 'Step3',
  mixins: [scrollToTopOnMountMixin],
  props: {
    updateCurrentStep: {
      type: Function,
      required: true,
    },
    bizInfo: {
      type: Object,
    },
  },
  components: {
    StartArrowIcon,
    Container,
  },
  setup(props) {
    onMounted(() => {
      if (!props.bizInfo) {
        // alert('사업자 인증이 필요합니다.'); // FIXME: remove comment
        // props.updateCurrentStep(1); // FIXME: remove comment
      }
    });

    const handleLogin = () => {
      router.push('/auth/signin');
    };

    return {
      handleLogin,
    };
  },
});
</script>

<style lang="scss" scoped></style>
