
import { defineComponent, ref, PropType, onMounted } from 'vue';
import StartArrowIcon from './assets/StartArrowIcon.vue';
import Container from '@/components/Container.vue';
import router from '@/router';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';

export default defineComponent({
  name: 'Step3',
  mixins: [scrollToTopOnMountMixin],
  props: {
    updateCurrentStep: {
      type: Function,
      required: true,
    },
    bizInfo: {
      type: Object,
    },
  },
  components: {
    StartArrowIcon,
    Container,
  },
  setup(props) {
    onMounted(() => {
      if (!props.bizInfo) {
        // alert('사업자 인증이 필요합니다.'); // FIXME: remove comment
        // props.updateCurrentStep(1); // FIXME: remove comment
      }
    });

    const handleLogin = () => {
      router.push('/auth/signin');
    };

    return {
      handleLogin,
    };
  },
});
