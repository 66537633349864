<template>
  <svg
    width="40"
    height="16"
    viewBox="0 0 40 16"
    fill="#49CDDA"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.3536 8.35355C38.5488 8.15829 38.5488 7.84171 38.3536 7.64645L35.1716 4.46447C34.9763 4.2692 34.6597 4.2692 34.4645 4.46447C34.2692 4.65973 34.2692 4.97631 34.4645 5.17157L37.2929 8L34.4645 10.8284C34.2692 11.0237 34.2692 11.3403 34.4645 11.5355C34.6597 11.7308 34.9763 11.7308 35.1716 11.5355L38.3536 8.35355ZM0.5 8.5H38V7.5H0.5V8.5Z"
      fill="#49CDDA"
    />
  </svg>
</template>
