<template>
  <div class="terms-area wrap_privacy">
    <h4 id="tab1" class="tit_policy">(주)서울옥션 개인정보 처리방침</h4>
    <p class="desc_g">
      (주)서울옥션(이하 ‘회사’라 한다)는 정보통신망 이용촉진 및 정보보호에 관한
      법률 및 개인정보 보호법에 따라 정보주체의 개인정보를 보호하고 이와 관련한
      고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보
      처리지침을 수립․공개합니다.<br /><br />본 방침은 2019년 6월 1일부터
      시행됩니다.
    </p>
    <strong class="tit_subject">제1조(개인정보의 처리목적)</strong>
    <p class="desc_g">
      회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
      개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이
      변경되는 경우에는 관련 법률에 따라 별도의 동의를 받는 등 필요한 조치를
      이행할 예정입니다.
    </p>
    <p class="desc_g">
      <span class="txt_num">1.</span> 홈페이지 회원 가입 및 관리
    </p>
    <p class="desc_g">
      회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별․인증, 회원자격
      유지․관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지
      등을 목적으로 개인정보를 처리합니다.
    </p>
    <p class="desc_g"><span class="txt_num">2.</span> 재화 또는 서비스 제공</p>
    <p class="desc_g">
      물품배송, 서비스 제공, 계약서․청구서 발송, 콘텐츠 제공, 맞춤서비스 제공,
      본인인증, 연령인증, 요금결제․정산, 채권추심 등을 목적으로 개인정보를
      처리합니다.
    </p>
    <p class="desc_g"><span class="txt_num">3.</span> 고충처리</p>
    <p class="desc_g">
      민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락․통지, 처리결과
      통보 등의 목적으로 개인정보를 처리합니다.
    </p>
    <p class="desc_g"><span class="txt_num">4.</span> 마케팅 및 광고에 활용</p>
    <p class="desc_g">
      이벤트 등 광고성 정보 전달, 접속 빈도 파악 또는 회원의 서비스 이용에 대한
      통계 등을 목적으로 개인정보를 처리합니다.
    </p>

    <strong class="tit_subject">제2조(개인정보의 처리 및 보유기간)</strong>
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>회사는 법령에 따른 개인정보 보유․이용기간
        또는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유․이용기간
        내에서 개인정보를 처리․보유합니다.
      </li>
      <li>
        <span class="txt_num">②</span>각각의 개인정보 처리 및 보유 기간은 다음과
        같습니다.
        <ul class="list_2depth_type2">
          <li>
            <span class="txt_num">1.</span>홈페이지 회원 가입 및 관리: 동의받은
            정보보유 이용기간 내<br />다만, 다음의 사유에 해당하는 경우에는 해당
            사유 종료시까지
            <ul class="list_3depth_type">
              <li>
                <span class="txt_num">1)</span>관계 법령 위반에 따른 수사․조사
                등이 진행중인 경우에는 해당 수사․조사 종료시까지
              </li>
              <li>
                <span class="txt_num">2)</span>홈페이지 이용에 따른
                채권․채무관계 잔존시에는 해당 채권․채무관계 정산시까지
              </li>
            </ul>
          </li>
          <li>
            <span class="txt_num">2.</span>재화 또는 서비스 제공 : 재화․서비스
            공급완료 및 요금결제․정산 완료시까지<br />다만, 다음의 사유에
            해당하는 경우에는 해당 기간 종료시까지
            <ul class="list_3depth_type">
              <li>
                <span class="txt_num">1)</span> 「전자상거래 등에서의 소비자
                보호에 관한 법률」에 따른 표시․광고, 계약내용 및 이행 등 거래에
                관한 기록
                <ul class="list_4depth_type">
                  <li>
                    <span class="txt_num">-</span> 표시․광고에 관한 기록 : 6월
                  </li>
                  <li>
                    <span class="txt_num">-</span> 계약 또는 청약철회, 대금결제,
                    재화 등의 공급기록 : 5년
                  </li>
                  <li>
                    <span class="txt_num">-</span> 소비자 불만 또는 분쟁처리에
                    관한 기록 : 3년
                  </li>
                </ul>
              </li>
              <li>
                <span class="txt_num">2)</span> 「통신비밀보호법」제41조에 따른
                통신사실확인자료 보관
                <ul class="list_4depth_type">
                  <li>
                    <span class="txt_num">-</span> 가입자 전기통신일시,
                    개시․종료시간, 상대방 가입자번호, 사용도수, 발신기지국
                    위치추적자료 : 1년
                  </li>
                  <li>
                    <span class="txt_num">-</span> 컴퓨터통신, 인터넷
                    로그기록자료, 접속지 추적자료 : 3개월
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <strong class="tit_subject">제3조(개인정보처리의 위탁)</strong>
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>
        회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를
        위탁하고 있습니다.
      </li>
      <li>
        <table class="privacy-table">
          <colgroup>
            <col width="50%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">위탁받는 자</th>
              <th scope="col">위탁하는 업무의 내용</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">㈜엘지유플러스</th>
              <td rowspan="2">문자 발송 서비스</td>
            </tr>
            <tr>
              <th scope="row">㈜바다정보</th>
            </tr>
            <tr>
              <th scope="row">우리특송</th>
              <td>우편물 발송 서비스</td>
            </tr>
            <tr>
              <th scope="row">ks특송</th>
              <td rowspan="12">작품 운송 서비스</td>
            </tr>
            <tr>
              <th scope="row">원탑운송</th>
            </tr>
            <tr>
              <th scope="row">㈜아트인아트</th>
            </tr>
            <tr>
              <th scope="row">아트피크</th>
            </tr>
            <tr>
              <th scope="row">아트드림</th>
            </tr>
            <tr>
              <th>H&h특송</th>
            </tr>
            <tr>
              <th>모두아트</th>
            </tr>
            <tr>
              <th scope="row">경민특송</th>
            </tr>
            <tr>
              <th>JM아트</th>
            </tr>
            <tr>
              <th>㈜아트랜스</th>
            </tr>
            <tr>
              <th scope="row">㈜동부아트</th>
            </tr>
            <tr>
              <th scope="row">㈜오양특송</th>
            </tr>
          </tbody>
        </table>
      </li>
      <li>
        <span class="txt_num">②</span>회사는 위탁계약 체결시 개인정보 보호법
        제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적
        보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한
        사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게
        처리하는지를 감독하고 있습니다.
      </li>
      <li>
        <span class="txt_num">③</span>위탁업무의 내용이나 수탁자가 변경될
        경우에는 지체 없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
      </li>
    </ul>
    <strong class="tit_subject">제4조(정보주체의 권리․의무 및 행사방법)</strong>
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>정보주체는 회사에 대해 언제든지 다음 각
        호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
        <ul class="list_2depth_type2">
          <li><span class="txt_num">1.</span>개인정보 열람요구</li>
          <li><span class="txt_num">2.</span>오류 등이 있을 경우 정정 요구</li>
          <li><span class="txt_num">3.</span>삭제요구</li>
          <li><span class="txt_num">4.</span>처리정지 요구</li>
        </ul>
      </li>
      <li>
        <span class="txt_num">②</span>제1항에 따른 권리 행사는 회사에 대해 서면,
        전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에
        대해 지체없이 조치하겠습니다.
      </li>
      <li>
        <span class="txt_num">③</span>정보주체가 개인정보의 오류 등에 대한 정정
        또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해
        개인정보를 이용하거나 제공하지 않습니다.
      </li>
      <li>
        <span class="txt_num">④</span>제1항에 따른 권리 행사는 정보주체의
        법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이
        경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을
        제출하셔야 합니다.
      </li>
      <li>
        <span class="txt_num">⑤</span>정보주체는 개인정보 보호법 등 관계법령을
        위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및
        사생활을 침해하여서는 아니됩니다.
      </li>
    </ul>
    <strong class="tit_subject">제5조(처리하는 개인정보 항목)</strong>
    <p class="desc_g">회사는 다음의 개인정보 항목을 처리하고 있습니다.</p>
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>필수항목 : 성명, 생년월일, 아이디,
        비밀번호, 주소, 전화번호, 성별, 이메일주소<br />선택항목 : 자택 전화번호
      </li>
      <li>
        <span class="txt_num">②</span>인터넷 서비스 이용과정에서 아래 개인정보
        항목이 자동으로 생성되어 수집될 수 있습니다.<br />IP주소, 쿠키, MAC주소,
        서비스 이용기록, 방문기록, 불량 이용기록 등
      </li>
    </ul>
    <strong class="tit_subject">제6조(개인정보의 파기)</strong>
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>회사는 개인정보 보유기간의 경과, 처리목적
        달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를
        파기합니다.
      </li>
      <li>
        <span class="txt_num">②</span>정보주체로부터 동의받은 개인정보
        보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에
        따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의
        데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
      </li>
      <li>
        <span class="txt_num">③</span>개인정보 파기의 절차 및 방법은 다음과
        같습니다.
        <ul class="list_2depth_type2">
          <li><span class="txt_num">1.</span>파기절차</li>
          <li>
            회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보
            보호책임자의 승인을 받아 개인정보를 파기합니다.
          </li>
          <li><span class="txt_num">2.</span>파기방법</li>
          <li>
            회사는 전자적 파일 형태로 기록․저장된 개인정보는 기록을 재생할 수
            없도록 로우레밸포멧(Low Level Format) 등의 방법을 이용하여 파기하며,
            종이 문서에 기록․저장된 개인정보는 분쇄기로 분쇄하거나 소각하여
            파기합니다.
          </li>
        </ul>
      </li>
    </ul>
    <strong class="tit_subject">제7조(개인정보의 안전성 확보조치)</strong>
    <p class="desc_g">
      회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
    </p>
    <ul class="list_1depth">
      <li>
        <span class="txt_num">1.</span>관리적 조치 : 내부관리계획 수립․시행,
        정기적 직원 교육 등
      </li>
      <li>
        <span class="txt_num">2.</span>기술적 조치 : 개인정보처리시스템 등의
        접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화,
        보안프로그램 설치
      </li>
      <li>
        <span class="txt_num">3.</span>물리적 조치 : 전산실, 자료보관실 등의
        접근통제
      </li>
    </ul>
    <strong class="tit_subject"
      >제8조(개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)</strong
    >
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>회사는 이용자에게 개별적인 맞춤서비스를
        제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를
        사용합니다.
      </li>
      <li>
        <span class="txt_num">②</span>쿠키는 웹사이트를 운영하는데 이용되는
        서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며
        이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
        <ul class="list_2depth_type2">
          <li>
            <span class="txt_num">가.</span>쿠키의 사용목적: 이용자가 방문한 각
            서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속
            여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
          </li>
          <li>
            <span class="txt_num">나.</span>쿠키의 설치∙운영 및 거부 :
            웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해
            쿠키 저장을 거부 할 수 있습니다.
          </li>
          <li>
            <span class="txt_num">다.</span>쿠키 저장을 거부할 경우 맞춤형
            서비스 이용에 어려움이 발생할 수 있습니다.
          </li>
        </ul>
      </li>
    </ul>
    <strong class="tit_subject">제9조(개인정보 보호책임자)</strong>
    <ul class="list_1depth">
      <li>
        <span class="txt_num">①</span>회사는 개인정보 처리에 관한 업무를
        총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및
        피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고
        있습니다.
      </li>
      <li>
        <strong class="tit_terms">▶ 개인정보 보호책임자</strong>
        <ul class="list_2depth">
          <li>성명 : 양동호</li>
          <li>직책 : IT팀 팀장</li>
          <li>
            연락처:☎02-2075-4335, hajenuri@seoulauction.com, (fax)02-395-0338
          </li>
        </ul>
        <p class="desc_g">※ 개인정보 보호 담당부서로 연결됩니다.</p>
        <strong class="tit_terms">▶ 개인정보 보호 담당부서</strong>
        <ul class="list_2depth">
          <li>부서명 : IT 팀</li>
          <li>
            연락처:☎02-2075-4335, hajenuri@seoulauction.com, (fax)02-395-0338
          </li>
        </ul>
      </li>
      <li>
        <span class="txt_num">②</span>보주체께서는 회사의 서비스(또는 사업)을
        이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제
        등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다.
        회사는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
      </li>
    </ul>
    <strong class="tit_subject">제10조(개인정보 열람청구)</strong>
    <p class="desc_g">
      정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의
      부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게
      처리되도록 노력하겠습니다.
    </p>
    <ul class="list_1depth">
      <li>
        <strong class="tit_terms">▶ 개인정보 열람청구 접수․처리 부서</strong>
        <ul class="list_2depth">
          <li>부서명 : IT팀</li>
          <li>담당자 : 양동호</li>
          <li>
            연락처:☎02-2075-4335, hajenuri@seoulauction.com, (fax)02-395-0338
          </li>
        </ul>
      </li>
    </ul>
    <strong class="tit_subject">제11조(권익침해 구제방법)</strong>
    <p class="desc_g">
      정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을
      문의하실 수 있습니다.
    </p>
    <p class="desc_g">
      &lt;아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보
      불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이
      필요하시면 문의하여 주시기 바랍니다&gt;
    </p>
    <ul class="list_1depth">
      <li>
        <strong class="tit_terms"
          >▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)</strong
        >
        <ul class="list_2depth_type2">
          <li>
            <span class="txt_num">-</span>소관업무 : 개인정보 침해사실 신고,
            상담 신청
          </li>
          <li><span class="txt_num">-</span>홈페이지 : privacy.kisa.or.kr</li>
          <li><span class="txt_num">-</span>전화 : (국번없이) 118</li>
          <li>
            <span class="txt_num">-</span>주소 : (58324) 전남 나주시 진흥길
            9(빛가람동 301-2) 3층 개인정보침해신고센터
          </li>
        </ul>
      </li>
      <li>
        <strong class="tit_terms">▶ 개인정보 분쟁조정위원회</strong>
        <ul class="list_2depth_type2">
          <li>
            <span class="txt_num">-</span>소관업무 : 개인정보 분쟁조정신청,
            집단분쟁조정 (민사적 해결)
          </li>
          <li><span class="txt_num">-</span>홈페이지 : www.kopico.go.kr</li>
          <li><span class="txt_num">-</span>전화 : (국번없이) 1833-6972</li>
          <li>
            <span class="txt_num">-</span>주소 : (03171)서울특별시 종로구
            세종대로 209 정부서울청사 4층
          </li>
        </ul>
      </li>
      <li>
        <strong class="tit_terms"
          >▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)</strong
        >
      </li>
      <li>
        <strong class="tit_terms"
          >▶ 경찰청 사이버안전국 : 182 (http://cyberbureau.police.go.kr)</strong
        >
      </li>
    </ul>
    <strong class="tit_subject">제12조(개인정보 처리방침 변경)</strong>
    <p class="desc_g">이 개인정보 처리방침은 2019. 6. 1. 부터 적용됩니다.</p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyContent',
};
</script>

<style scoped lang="scss">
.terms-area {
  max-height: 400px;
  overflow-y: auto;
}
.tit_policy {
  margin-top: 55px;
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 600;
  &:first-child {
    margin-top: 0;
    border-top: 0 none;
  }
}

.cont_policy .desc_g {
  padding-top: 28px;
  line-height: 26px;
}

.tit_subject {
  display: block;
  padding-top: 10px;
  line-height: 28px;

  &.fst {
    padding-top: 53px;
  }
  + .desc_g {
    padding-top: 18px;
  }
}

.list_1depth {
  padding-left: 25px;

  .txt_num {
    margin: 0 12px 0 -25px;
  }
}

.list_1depth_type1 {
  margin-top: 25px;
  padding: 24px 0 0 39px;
  border-top: 1px solid #e6e6e6;

  &.fst {
    margin-top: 0;
    padding-top: 8px;
    border-top: 0 none;
  }

  li {
    font-size: 14px;

    &:before {
      display: inline-block;
      width: 2px;
      height: 2px;
      margin: 12px 14px 0 -16px;
      vertical-align: top;
      background-color: #1e1e1e;
      content: '';
    }
  }
}

.list_2depth_type2 {
  padding: 10px 0 0 40px;
  li {
    font-size: 14px;
  }
  .txt_num {
    display: inline-block;
    min-width: 15px;
    margin: 0 4px 0 -19px;
    vertical-align: top;
  }
}

.list_1depth .list_2depth_type2 {
  padding-left: 19px;
  li {
    padding-top: 0;
  }
}

.list_2depth_type3 {
  padding: 10px 0 0 19px;
  li {
    padding-top: 0;
    font-size: 14px;
    line-height: 26px;
    list-style: decimal;
  }
}

.list_3depth_type,
.list_4depth_type {
  padding-left: 19px;
}

.list_3depth_type li {
  font-size: 13px;
}
.wrap_privacy .tit_policy {
  margin-top: 55px;
  border-top: 1px solid #e6e6e6;
  &:first-child {
    margin-top: 0;
    border-top: 0 none;
  }
}

.privacy-table {
  th {
    border-bottom: 0 none;
    background-color: #f7f7f7;
    text-align: center;
    font-weight: normal;
  }
  th,
  td {
    padding: 5px 12px 3px;
    border: 1px solid #ddd;
    text-align: left;
  }
}
</style>
