<template>
  <Container :width="1200" :style="{ padding: 0 }">
    <div class="FormSection">
      <div class="FormSection__title">기본 정보</div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>아이디</RequiredLabel>
        </div>
        <div class="FormSection__field">
          <input type="email" class="input-hidden">
          <input type="password" class="input-hidden">
          <Input
            type="email"
            placeholder="이메일"
            className="FormSection__input"
            :value="partnerData.email"
            @input="
              (e) => {
                partnerData.email = e.target.value;
                validateEmail(e.target.value);
              }
            "
            :onBlur="emailCheck"
          ></Input>
          <div
            v-if="accountValid.email === 'START'"
            class="FormSection__fieldGuide"
          >
            아이디로 이메일 주소를 입력해주세요. 추후 변경할 수 있습니다.
          </div>
          <div
            v-if="
              accountValid.email === 'VALID' &&
              accountValid.emailNotDuplicate === 'NOT_VALID'
            "
            class="FormSection__fieldGuide text-red-600"
          >
            이미 가입된 이메일 주소입니다.
          </div>
          <div
            v-if="accountValid.email === 'NOT_VALID'"
            class="FormSection__fieldGuide text-red-600"
          >
            잘못된 이메일 주소입니다.
          </div>
          <div
            v-if="
              accountValid.email === 'VALID' &&
              accountValid.emailNotDuplicate === 'VALID'
            "
            class="FormSection__fieldGuide text-blue-600"
          >
            유효한 이메일 주소입니다.
          </div>
        </div>
      </div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>비밀번호</RequiredLabel>
        </div>
        <div class="FormSection__field">
          <Input
            type="password"
            className="FormSection__input"
            placeholder="비밀번호"
            :value="partnerData.password"
            @input="
              (e) => {
                partnerData.password = e.target.value;
                validatePassword(e.target.value);
              }
            "
          ></Input>
          <div
            v-if="accountValid.password === 'START'"
            class="FormSection__fieldGuide"
          >
            영문, 숫자, 특수문자 조합으로 6~15자를 입력해주세요.
          </div>
          <div
            v-if="accountValid.password === 'NOT_VALID'"
            class="FormSection__fieldGuide text-red-600"
          >
            영문, 숫자, 특수문자 조합으로 6~15자를 입력해주세요.
          </div>
          <div
            v-if="accountValid.password === 'VALID'"
            class="FormSection__fieldGuide text-blue-600"
          >
            유효한 비밀번호입니다.
          </div>
        </div>
      </div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>비밀번호 확인</RequiredLabel>
        </div>
        <div class="FormSection__field">
          <Input
            type="password"
            className="FormSection__input"
            placeholder="비밀번호 확인"
            :value="partnerData.passwordConfirm"
            @input="
              (e) => {
                partnerData.passwordConfirm = e.target.value;
                validatePasswordConfirm(e.target.value);
              }
            "
          ></Input>
          <div
            v-if="accountValid.passwordConfirm === 'START'"
            class="FormSection__fieldGuide"
          >
            비밀번호 확인을 위해 위에서 입력한 비밀번호를 한번 더 입력해주세요.
          </div>
          <div
            v-if="accountValid.passwordConfirm === 'NOT_VALID'"
            class="FormSection__fieldGuide text-red-600"
          >
            비밀번호가 일치하지 않습니다.
          </div>
          <div
            v-if="accountValid.passwordConfirm === 'VALID'"
            class="FormSection__fieldGuide text-blue-600"
          >
            비밀번호가 일치합니다!
          </div>
        </div>
      </div>
    </div>
    <div class="FormSection">
      <div class="FormSection__title">사업자 정보</div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>사업자 등록번호</RequiredLabel>
        </div>
        <div class="FormSection__field">
          <Input
            type="text"
            className="FormSection__input"
            :disabled="true"
            :value="partnerData.businessNumber"
          ></Input>
          <div class="FormSection__fieldGuide">
            사업자 정보를 변경하시려면 Step1으로 돌아가서 새로운 사업자로
            인증해주세요.
          </div>
        </div>
      </div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>상호</RequiredLabel>
        </div>
        <div class="FormSection__field">
          <Input
            type="text"
            className="FormSection__input"
            :disabled="false"
            :value="partnerData.companyName"
            @change="partnerData.companyName = $event.target.value"
          ></Input>
        </div>
      </div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>대표자 이름</RequiredLabel>
        </div>
        <div class="FormSection__field">
          <Input
            type="text"
            className="FormSection__input"
            :disabled="false"
            :value="partnerData.ceoName"
            @change="partnerData.ceoName = $event.target.value"
          ></Input>
        </div>
      </div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>대표자 휴대폰</RequiredLabel>
        </div>
        <div class="FormSection__field flex">
          <div class="FormSection__phoneNumberGrid flex items-center">
            <Input
              type="text"
              className="number"
              :value="partnerData.ceoMobile1"
              :disabled="true"
              @change="partnerData.ceoMobile1 = $event.target.value"
            ></Input>
            <div class="hyphen">-</div>
            <Input
              type="text"
              className="number"
              :value="partnerData.ceoMobile2"
              :disabled="true"
              @change="partnerData.ceoMobile2 = $event.target.value"
            ></Input>
            <div class="hyphen">-</div>
            <Input
              type="text"
              className="number"
              :value="partnerData.ceoMobile3"
              :disabled="true"
              @change="partnerData.ceoMobile3 = $event.target.value"
            ></Input>
          </div>
          <button
            class="ml-4 w-24 border border-blue-sky text-xs"
            @click="openMobileAuth"
          >
            인증하기
          </button>
        </div>
      </div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>업태</RequiredLabel>
        </div>
        <div class="FormSection__field">
          <Input
            type="text"
            className="FormSection__input"
            :value="partnerData.industry"
            @change="partnerData.industry = $event.target.value"
          ></Input>
        </div>
      </div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>종목</RequiredLabel>
        </div>
        <div class="FormSection__field">
          <Input
            type="text"
            className="FormSection__input"
            :value="partnerData.sector"
            @change="partnerData.sector = $event.target.value"
          ></Input>
        </div>
      </div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>주소</RequiredLabel>
        </div>
        <div class="FormSection__field">
          <div class="flex items-center">
            <Input
              type="text"
              className="w-20"
              :disabled="true"
              :value="partnerData.postNo"
              @change="partnerData.postNo = $event.target.value"
            ></Input>
            <!-- 우편번호 -->
            <button
              class="ml-4 w-24 h-10 border border-blue-sky text-xs"
              @click="handleOpenSearchAddress"
            >
              주소검색
            </button>
          </div>
          <div class="mt-4">
            <!-- 검색한 주소 -->
            <Input
              type="text"
              className="FormSection__input"
              :disabled="true"
              :value="partnerData.address1"
              @change="partnerData.address1 = $event.target.value"
            ></Input>
            <!-- 상세주소 -->
            <Input
              type="text"
              className="FormSection__input ml-4 w-52"
              :value="partnerData.address2"
              @change="partnerData.address2 = $event.target.value"
            ></Input>
          </div>
        </div>
      </div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">통신판매업 신고번호</div>
        <div class="FormSection__field">
          <Input
            type="text"
            className="FormSection__input"
            :value="partnerData.onlineSalesBusinessNo"
            @change="partnerData.onlineSalesBusinessNo = $event.target.value"
          ></Input>
        </div>
      </div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>고객문의 연락처</RequiredLabel>
        </div>
        <div class="FormSection__field flex">
          <div class="FormSection__phoneNumberGrid flex items-center">
            <Input
              type="number"
              className="number"
              :value="partnerData.csTelNo1"
              @change="partnerData.csTelNo1 = $event.target.value"
            ></Input>
            <div class="hyphen">-</div>
            <Input
              type="number"
              className="number"
              :value="partnerData.csTelNo2"
              @change="partnerData.csTelNo2 = $event.target.value"
            ></Input>
            <div class="hyphen">-</div>
            <Input
              type="number"
              className="number"
              :value="partnerData.csTelNo3"
              @change="partnerData.csTelNo3 = $event.target.value"
            ></Input>
          </div>
        </div>
      </div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>대표 이메일</RequiredLabel>
        </div>
        <div class="FormSection__field">
          <Input
            type="email"
            className="FormSection__input"
            :value="partnerData.csEmail"
            @change="partnerData.csEmail = $event.target.value"
          ></Input>
          <div class="FormSection__fieldGuide">
            사업자정보는 셀러홈, 상품 및 판매 상세페이지, 주문내역에 노출됩니다.
          </div>
        </div>
      </div>
    </div>
    <div class="FormSection">
      <div class="FormSection__title">운영담당자 정보</div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>운영담당자 이름</RequiredLabel>
        </div>
        <div class="FormSection__field">
          <Input
            type="text"
            className="FormSection__input"
            :value="partnerData.managerName"
            @change="partnerData.managerName = $event.target.value"
          ></Input>
          <div class="FormSection__fieldGuide"></div>
        </div>
      </div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>운영담당자 휴대폰</RequiredLabel>
        </div>
        <div class="FormSection__field flex">
          <div class="FormSection__phoneNumberGrid flex items-center">
            <Input
              type="number"
              className="number"
              :value="partnerData.managerMobile1"
              @change="partnerData.managerMobile1 = $event.target.value"
            ></Input>
            <div class="hyphen">-</div>
            <Input
              type="number"
              className="number"
              :value="partnerData.managerMobile2"
              @change="partnerData.managerMobile2 = $event.target.value"
            ></Input>
            <div class="hyphen">-</div>
            <Input
              type="number"
              className="number"
              :value="partnerData.managerMobile3"
              @change="partnerData.managerMobile3 = $event.target.value"
            ></Input>
          </div>
        </div>
      </div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>운영담당자 이메일</RequiredLabel>
        </div>
        <div class="FormSection__field">
          <Input
            type="email"
            className="FormSection__input"
            :value="partnerData.managerEmail"
            @change="partnerData.managerEmail = $event.target.value"
          ></Input>
          <div class="FormSection__fieldGuide"></div>
        </div>
      </div>
    </div>
    <div class="FormSection">
      <div class="FormSection__title">정산 정보</div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>입금 계좌</RequiredLabel>
        </div>
        <div class="FormSection__field flex items-center">
          <div class="FormSection__BankAccountGrid flex items-center">
            <Select
              :options="bankOptions"
              :value="partnerData.bankCode"
              :onChange="
                (e) => {
                  partnerData.bankCode = e.target.value;
                  partnerData.bankName = bankOptions.find(
                    (option) => option.value === e.target.value
                  ).label;
                  isBankAuth = false;
                  partnerData.accountHolder = '';
                }
              "
              placeholder="은행선택"
              className="border-black"
            ></Select>
            <div class="hyphen">-</div>
            <input
              type="number"
              placeholder="계좌번호는 숫자만 입력해주세요."
              class="
                FormSection__input
                relative
                h-10
                border
                inline-flex
                px-4
                text-sm
                border-black
              "
              v-model="partnerData.bankAccount"
              @Keyup="
                () => {
                  onlyNumber(partnerData.bankAccount);
                  isBankAuth = false;
                  partnerData.accountHolder = '';
                }
              "
            />
          </div>
          <button
            @click="handleBankAuth"
            class="ml-4 w-24 border border-blue-sky text-xs h-10"
          >
            예금주명 조회
          </button>
        </div>
      </div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>예금주</RequiredLabel>
        </div>
        <div class="FormSection__field flex items-center">
          <Input
            type="text"
            disabled
            className="FormSection__input bg-gray-200"
            :value="partnerData.accountHolder"
          ></Input>
        </div>
      </div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>정산담당자 이름</RequiredLabel>
        </div>
        <div class="FormSection__field flex items-center">
          <Input
            type="text"
            className="FormSection__input"
            :value="partnerData.paymentManagerName"
            @change="partnerData.paymentManagerName = $event.target.value"
          ></Input>
          <Checkbox
            :checked="isSamePerson"
            :onChange="
              (e) => {
                isSamePerson = e.target.checked;
                handleManagerField();
              }
            "
            className="ml-4"
            name="same__as__opeartor"
            label="운영담당자와 동일"
          ></Checkbox>
        </div>
      </div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>정산담당자 휴대폰</RequiredLabel>
        </div>
        <div class="FormSection__field flex">
          <div class="FormSection__phoneNumberGrid flex items-center">
            <Input
              type="number"
              className="number"
              :value="partnerData.paymentManagerMobile1"
              @change="partnerData.paymentManagerMobile1 = $event.target.value"
            ></Input>
            <div class="hyphen">-</div>
            <Input
              type="number"
              className="number"
              :value="partnerData.paymentManagerMobile2"
              @change="partnerData.paymentManagerMobile2 = $event.target.value"
            ></Input>
            <div class="hyphen">-</div>
            <Input
              type="number"
              className="number"
              :value="partnerData.paymentManagerMobile3"
              @change="partnerData.paymentManagerMobile3 = $event.target.value"
            ></Input>
          </div>
        </div>
      </div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>정산담당자 이메일</RequiredLabel>
        </div>
        <div class="FormSection__field">
          <Input
            type="email"
            className="FormSection__input"
            :value="partnerData.paymentManagerEmail"
            @change="partnerData.paymentManagerEmail = $event.target.value"
          ></Input>
        </div>
      </div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>사업자등록증 사본</RequiredLabel>
        </div>
        <div class="FormSection__field">
          <div class="flex items-center">
            <HiddenFileInput
              className="w-24 h-10 border border-blue-sky text-xs"
              :onChange="(e) => handleChangeFile('businessLicense')(e)"
              >파일첨부</HiddenFileInput
            >
            <div
              v-if="imgFiles.businessLicense"
              class="FormFileImage__field flex items-center"
            >
              <IconBase>
                <FileImage></FileImage>
              </IconBase>
              <p class="text-xs ml-2">{{ imgFiles.businessLicense }}</p>
            </div>
          </div>
          <div class="FormSection__fieldGuide">
            1년 이내에 발급 받은 사업자등록증 또는 사업자등록증명원을 첨부해
            주시기 바랍니다.
          </div>
        </div>
      </div>
      <div class="FormSection__formRow">
        <div class="FormSection__label">
          <RequiredLabel>통장 사본</RequiredLabel>
        </div>
        <div class="FormSection__field">
          <div class="flex items-center">
            <HiddenFileInput
              className="w-24 h-10 border border-blue-sky text-xs"
              :onChange="(e) => handleChangeFile('bankbookCopy')(e)"
              >파일첨부</HiddenFileInput
            >
            <div
              v-if="imgFiles.bankbookCopy"
              class="FormFileImage__field flex items-center"
            >
              <IconBase>
                <FileImage></FileImage>
              </IconBase>
              <p class="text-xs ml-2">{{ imgFiles.bankbookCopy }}</p>
            </div>
          </div>
          <div class="FormSection__fieldGuide">
            사업자등록증 사본과 통장사본은 이미지 파일(jpg, jpeg, png 또는
            PDF)만 첨부할 수 있으며, 파일의 최대 용량은 5MB를 초과할 수
            없습니다.
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center mt-10 mb-10">
      <button
        class="
          flex
          items-center
          justify-center
          border border-black
          shadow-sm
          w-60
          h-16
        "
        @click="goToNextStep"
      >
        <IconBase>
          <CheckCircleIcon></CheckCircleIcon>
        </IconBase>
        <span class="ml-2">동의하고 가입하기</span>
      </button>
      <div id="form" style="display: none"></div>
    </div>
  </Container>
</template>

<script lang="ts">
import Input from '@/components/Input.vue';
import RequiredLabel from '@/components/RequiredLabel.vue';
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import IconBase from '@/components/icons/IconBase.vue';
import Checkbox from '@/components/Checkbox.vue';
import Container from '@/components/Container.vue';
import HiddenFileInput from '@/components/HiddenFileInput.vue';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import uploadSignupImageFiles from '@/utils/uploadSignupImageFiles';
import { PartnerAuthApiJoinPartnerUsingPOST1Request } from '@/openapi';
import getServerErrorMessage from '@/utils/getServerErrorMessage';
import Select from '@/components/Select.vue';
import FileImage from '@/components/icons/FileImage.vue';
import bankCode from '@/constants/bankCode';

export default defineComponent({
  name: 'Step2',
  mixins: [scrollToTopOnMountMixin],
  props: {
    updateCurrentStep: {
      type: Function,
      required: true,
    },
    bizInfo: {
      type: Object,
    },
  },
  components: {
    FileImage,
    Input,
    Select,
    RequiredLabel,
    CheckCircleIcon,
    IconBase,
    Checkbox,
    HiddenFileInput,
    Container,
  },
  setup(props) {
    // 파트너 가입 데이터
    const partnerData = ref<any>({} as any);
    const bankOptions = ref(bankCode);
    const isBankAuth = ref<boolean>(false);
    const isSamePerson = ref(false);
    const imgFiles = ref({
      bankbookCopy: '',
      businessLicense: '',
    });
    // 'START', 'VALID', 'NOT_VALID'
    const accountValid = ref({
      email: 'START',
      emailNotDuplicate: 'START',
      password: 'START',
      passwordConfirm: 'START',
    });
    const onlyNumber = (input) => {
      console.log(input);
      partnerData.value.bankAccount = input.replace(/[^0-9]/g, '');
    };
    const validateEmail = (email) => {
      console.log('email');
      const emailReg = /^[a-z0-9_+.-]+@([a-z0-9-]+\.)+[a-z0-9]{2,8}$/;
      accountValid.value.email = emailReg.test(email) ? 'VALID' : 'NOT_VALID';
      accountValid.value.emailNotDuplicate = 'START';
    };

    const validatePassword = (password) => {
      console.log('password');
      if (!password) {
        accountValid.value.password = 'START';
        return;
      }
      const pwReg =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@!%*#?&^~+=`-])[A-Za-z\d$@!%*#?&^~+=`-]{6,15}$/;
      accountValid.value.password = pwReg.test(password)
        ? 'VALID'
        : 'NOT_VALID';
      if (partnerData.value.passwordConfirm) {
        validatePasswordConfirm(partnerData.value.passwordConfirm);
      }
    };

    const validatePasswordConfirm = (passwordConfirm) => {
      console.log('passwordConfirm');
      if (!partnerData.value.password) {
        accountValid.value.passwordConfirm = 'START';
        return;
      }
      accountValid.value.passwordConfirm =
        partnerData.value.password === passwordConfirm ? 'VALID' : 'NOT_VALID';
    };

    const emailCheck = async () => {
      console.log('email check');
      if (!partnerData.value.email || !accountValid.value.email) {
        return;
      }
      try {
        const { data } = await partnerAPI.partnerAuth.checkEmailUsingPOST1({
          email: partnerData.value.email,
        });
        const { success, message } = data as any;
        accountValid.value.emailNotDuplicate = success ? 'VALID' : 'NOT_VALID';
        console.log(success, message);
      } catch (e) {
        alert(getServerErrorMessage(e));
      }
    };

    onMounted(() => {
      if (!props.bizInfo) {
        alert('사업자 인증이 필요합니다.'); // FIXME: remove comment
        props.updateCurrentStep(1); // FIXME: remove comment
      } else {
        console.log(props.bizInfo);

        partnerData.value = props.bizInfo;
        // console.log(partnerData.value["businessNumber"]);
      }
    });
    const goToNextStep = async () => {
      if (accountValid.value.email !== 'VALID') {
        alert('아이디를 이메일 형식으로 입력해 주세요.');
        return;
      }
      if (accountValid.value.emailNotDuplicate !== 'VALID') {
        alert('이메일 중복을 확인해 주세요.');
        return;
      }
      if (accountValid.value.password !== 'VALID') {
        alert(
          '비밀번호는 영문, 숫자, 특수문자 조합으로 6~15자를 입력해주세요.'
        );
        return;
      }
      if (accountValid.value.passwordConfirm !== 'VALID') {
        alert('비밀번호 확인이 일치하지 않습니다.');
        return;
      }
      if (!isBankAuth.value || !partnerData.value.accountHolder) {
        alert('예금주명을 조회해 주세요.');
        return;
      }

      console.log('partnerData : ', partnerData.value);
      partnerData.value['ceoMobile'] =
        partnerData.value['ceoMobile1'] +
        partnerData.value['ceoMobile2'] +
        partnerData.value['ceoMobile3'];
      partnerData.value['managerMobile'] =
        partnerData.value['managerMobile1'] +
        partnerData.value['managerMobile2'] +
        partnerData.value['managerMobile3'];
      partnerData.value['paymentManagerMobile'] =
        partnerData.value['paymentManagerMobile1'] +
        partnerData.value['paymentManagerMobile2'] +
        partnerData.value['paymentManagerMobile3'];
      partnerData.value['csTelNo'] =
        partnerData.value['csTelNo1'] +
        partnerData.value['csTelNo2'] +
        partnerData.value['csTelNo3'];

      if (partnerData.value['receiveMarketingEmailCheck'] === 'on') {
        partnerData.value['receiveMarketingEmail'] = true;
      } else {
        partnerData.value['receiveMarketingEmail'] = false;
      }
      if (partnerData.value['receiveMarketingSmsCheck'] === 'on') {
        partnerData.value['receiveMarketingSms'] = true;
      } else {
        partnerData.value['receiveMarketingSms'] = false;
      }

      const submitData = { param: { ...partnerData.value } };
      try {
        const { data } = await partnerAPI.partnerAuth.joinPartnerUsingPOST1(
          submitData as PartnerAuthApiJoinPartnerUsingPOST1Request
        );
        if ((data as any).success === true) {
          props.updateCurrentStep(3);
        } else {
          alert((data as any).message);
        }
      } catch (e) {
        console.error(e);
        alert(getServerErrorMessage(e));
      }

      // if (props.bizInfo) {
      //   props.updateCurrentStep(2);
      // } else {
      //   alert('사업자 번호 인증이 필요합니다.');
      // }
    };

    const handleBankAuth = async () => {
      try {
        const { data } = await partnerAPI.partnerAuth.companyCheckUsingPOST({
          param: {
            bankCode: partnerData.value.bankCode,
            bankAccount: partnerData.value.bankAccount,
          },
        });
        if ((data as any).success) {
          isBankAuth.value = true;
          partnerData.value.accountHolder = (data as any).data;
        } else {
          isBankAuth.value = false;
          partnerData.value.accountHolder = '';
        }
        alert((data as any).message);
      } catch (e) {
        console.error(e);
        isBankAuth.value = false;
        partnerData.value.accountHolder = '';
        alert(getServerErrorMessage(e));
      }
    };

    const handleChangeFile = (fieldName: string) => async (e) => {
      // TODO: 서버에서 이미지 업로드에 인증 토큰 요구함. 파트너 가입에서 쓸 수 있는 방법 필요.
      const [url] = await uploadSignupImageFiles({
        files: e.target.files as FileList,
      });
      imgFiles.value[fieldName] = e.target.files && e.target.files[0].name;
      console.log('url', url, e.target, fieldName);
      if (url) {
        partnerData.value[fieldName] = url;
      } else {
        alert('이미지 업로드에 실패했습니다.');
      }
    };

    const handleOpenSearchAddress = () => {
      new (window.daum as any).Postcode({
        oncomplete: function (data) {
          partnerData.value['postNo'] = data.zonecode;
          partnerData.value['address1'] = data.address;
          // console.group('postcode');
          // console.log('address ', data.address);
          // console.log('zonecode ', data.zonecode);
          // console.groupEnd();
        },
      }).open();
    };

    const handleManagerField = () => {
      const {
        managerName,
        managerMobile1,
        managerMobile2,
        managerMobile3,
        managerEmail,
      } = partnerData.value;
      if (isSamePerson.value) {
        // 복사하기
        partnerData.value.paymentManagerName = managerName;
        partnerData.value.paymentManagerMobile1 = managerMobile1;
        partnerData.value.paymentManagerMobile2 = managerMobile2;
        partnerData.value.paymentManagerMobile3 = managerMobile3;
        partnerData.value.paymentManagerEmail = managerEmail;
      } else {
        partnerData.value.paymentManagerName = '';
        partnerData.value.paymentManagerMobile1 = '';
        partnerData.value.paymentManagerMobile2 = '';
        partnerData.value.paymentManagerMobile3 = '';
        partnerData.value.paymentManagerEmail = '';
      }
    };

    const openMobileAuth = async () => {
      const { data } = await partnerAPI.memberAuth.identificationInfoUsingGET();
      const encodeData = (data as any).data;

      const form = document.createElement('form');
      form.setAttribute('name', 'formChk');
      form.setAttribute(
        'action',
        'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb'
      );
      form.setAttribute('target', 'popupChk');

      const field = document.createElement('input');
      field.setAttribute('name', 'm');
      field.setAttribute('value', 'checkplusService');
      form.appendChild(field);

      const field2 = document.createElement('input');
      field2.setAttribute('name', 'EncodeData');
      field2.setAttribute('value', encodeData);
      form.appendChild(field2);

      const htmlForm = document.getElementById('form');
      const chkForm = document.getElementById('formChk');
      if (chkForm) {
        if (chkForm.parentNode != null) {
          chkForm.parentNode.removeChild(chkForm);
        }
      }
      if (htmlForm != null) {
        htmlForm.append(form);
      }

      window.open(
        '',
        'popupChk',
        'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no'
      );
      form.submit();
    };

    const mobileAuthCallbackHandler = ({ data, origin }) => {
      if (origin.includes('api.blacklot.com')) {
        console.log('휴대퐁 인증 콜백 들어옴');
        console.log(data);
        const { name, birth, dupInfo, gender, mobile } = JSON.parse(data);
        partnerData.value['ceoMobile'] = mobile;
        partnerData.value['ceoMobile1'] = mobile.substring(0, 3);
        partnerData.value['ceoMobile2'] = mobile.substring(3, 7);
        partnerData.value['ceoMobile3'] = mobile.substring(7);
        // input.value.name = decodeURI(name)
        // input.value.birth = birth
        // input.value.dupInfo = dupInfo
        // input.value.gender = gender === '1' ? '남자' : '여자'
        // input.value.tel = mobile
      }
    };

    onMounted(() => {
      window.addEventListener('message', mobileAuthCallbackHandler);
    });
    onUnmounted(() => {
      window.removeEventListener('message', mobileAuthCallbackHandler);
    });

    return {
      isSamePerson,
      bankOptions,
      partnerData,
      isBankAuth,
      imgFiles,
      accountValid,
      validateEmail,
      onlyNumber,
      validatePassword,
      validatePasswordConfirm,
      emailCheck,
      handleManagerField,
      handleBankAuth,
      handleChangeFile,
      openMobileAuth,
      handleOpenSearchAddress,
      goToNextStep,
    };
  },
});
</script>

<style lang="scss" scoped>
.FormSection {
  border: 1px solid #dddddd;
  padding: 32px 40px;

  & + & {
    margin-top: 40px;
  }
}

.FormSection__title {
  font-size: 20px;
  line-height: 20px;
  color: #171717;
  font-weight: bold;
}

.FormSection__formRow {
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
}

.FormSection__label {
  width: 312px;
  height: 40px;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 32px;
}

.FormSection__field {
  flex: 1;
}

.FormSection__input {
  width: 340px;
}

.FormSection__fieldGuide {
  font-size: 12px;
  line-height: 14px;
  color: #555555;
  margin-top: 8px;
  &.text-red-600 {
    color: #ff0000;
  }
  &.text-blue-600 {
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
}
.FormSection__BankAccountGrid {
  & > .hyphen {
    display: inline-flex;
    justify-content: center;
    width: 28px;
  }
  .FormSelect {
    width: 120px;
  }
}
.FormSection__phoneNumberGrid {
  width: 340px;

  & > .hyphen {
    display: inline-flex;
    justify-content: center;
    width: 28px;
  }

  & > .number {
    width: 96px;
  }
}
.FormFileImage__field {
  margin-left: 20px;
}
.input-hidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}
</style>
