<template>
  <Container :width="1200" :style="{ padding: 0 }">
    <div
      class="py-8 px-22 border border-gray-300 flex items-center justify-center"
    >
      <div class="text-sm mr-8 flex">
        <RequiredLabel>사업자 등록번호</RequiredLabel>
      </div>
      <Input
        type="number"
        className="CompnayNumberInput"
        :value="bizNumber.part1"
        maxlength="3"
        @change="
          (e) => {
            bizNumber.part1 = e.target.value;
            isBiznumberCertificated = false;
          }
        "
        @keyup="nextFocus($event.target.value, 2)"
      />
      <span class="mx-3">-</span>
      <Input
        type="number"
        className="CompnayNumberInput"
        :value="bizNumber.part2"
        maxlength="2"
        @change="
          (e) => {
            bizNumber.part2 = e.target.value;
            isBiznumberCertificated = false;
          }
        "
        ref="input2"
        @keyup="nextFocus($event.target.value, 3)"
      />
      <span class="mx-3">-</span>
      <Input
        type="number"
        className="CompnayNumberInput"
        :value="bizNumber.part3"
        maxlength="5"
        @change="
          (e) => {
            bizNumber.part3 = e.target.value;
            isBiznumberCertificated = false;
          }
        "
        ref="input3"
      />
      <button
        class="
          bg-black
          text-white
          inline-flex
          items-center
          px-4
          h-10
          ml-8
          text-xs
        "
        @click="handleCertificateBizNumber"
      >
        <IconBase>
          <BusinessIdIcon></BusinessIdIcon>
        </IconBase>
        사업자 인증
      </button>
    </div>
    <ul class="list-disc ml-6 mt-6">
      <li>사업자등록번호가 있는 사업자에 한해 파트너 회원가입이 가능합니다.</li>
      <li>
        사업자등록번호가 인증이 되지 않을 경우 NICE평가정보 고객센터로
        문의해주세요. (02-3771-1011)
      </li>
    </ul>
    <!-- 약관 -->
    <div class="border-l border-r border-b mt-10">
      <div>
        <!-- collapse head -->
        <div :class="classes.collapseHead" @click="handleOpenTerms(1)">
          <IconBase :width="24" :height="24" viewBox="0 0 24 24">
            <ArrowDownIcon></ArrowDownIcon>
          </IconBase>
          <span class="ml-7">
            <span class="text-red-500">[필수]</span>
            <span :class="classes.collapseHeadTitle"
              >블랙랏 파트너센터 이용약관</span
            >
          </span>
        </div>
        <terms-content
          v-if="openedTermsIndex === 1"
          :class="classes.collapseBody"
        />
      </div>
      <div>
        <!-- collapse head -->
        <div :class="classes.collapseHead" @click="handleOpenTerms(2)">
          <IconBase :width="24" :height="24" viewBox="0 0 24 24">
            <ArrowDownIcon></ArrowDownIcon>
          </IconBase>
          <span class="ml-7">
            <span class="text-red-500">[필수]</span>
            <span :class="classes.collapseHeadTitle">개인정보취급방침</span>
          </span>
        </div>
        <privacy-content
          v-if="openedTermsIndex === 2"
          :class="classes.collapseBody"
        />
      </div>
    </div>
    <div class="mt-10 flex justify-center mb-10">
      <button
        class="
          flex
          items-center
          justify-center
          border border-black
          shadow-sm
          w-60
          h-16
        "
        @click="goToNextStep"
      >
        <IconBase>
          <CheckCircleIcon></CheckCircleIcon>
        </IconBase>
        <span class="ml-2">동의하고 가입하기</span>
      </button>
    </div>
  </Container>
</template>
<script lang="ts">
import Input from '@/components/Input.vue';
import { defineComponent, ref, reactive } from 'vue';
import RequiredLabel from '@/components/RequiredLabel.vue';
import BusinessIdIcon from '@/components/icons/BusinessIdIcon.vue';
import ArrowDownIcon from '@/components/icons/ArrowDownIcon.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import IconBase from '@/components/icons/IconBase.vue';
import Container from '@/components/Container.vue';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import TermsContent from '@/views/SignUp/TermsContent.vue';
import PrivacyContent from '@/views/SignUp/PrivacyContent.vue';

export default defineComponent({
  name: 'Step1',
  mixins: [scrollToTopOnMountMixin],
  props: {
    updateCurrentStep: {
      type: Function,
      required: true,
    },
    updateBizInfo: {
      type: Function,
      required: true,
    },
    bizInfo: {
      type: Object,
    },
  },
  components: {
    Input,
    RequiredLabel,
    BusinessIdIcon,
    IconBase,
    CheckCircleIcon,
    ArrowDownIcon,
    Container,
    TermsContent,
    PrivacyContent,
  },
  setup(props) {
    const data = ref(0);

    const classes = {
      collapseHead: 'flex items-center h-18 px-6 border-t cursor-pointer',
      collapseBody:
        'border-t p-12 break-words font-sans whitespace-pre-wrap text-gray-400',
      collapseHeadTitle: 'ml-2 font-bold',
    };

    const openedTermsIndex = ref(1); // 약관 영역 toggle을 위한 인덱스

    const handleOpenTerms = (index: number) => {
      openedTermsIndex.value = index;
    };

    const bizNumber = reactive({
      part1: '',
      part2: '',
      part3: '',
    });

    const isBiznumberCertificated = ref(false);

    const handleCertificateBizNumber = async () => {
      console.group('cert biznumber');
      console.log('bizNumber.part1', bizNumber.part1);
      console.log('bizNumber.part2', bizNumber.part2);
      console.log('bizNumber.part3', bizNumber.part3);
      console.groupEnd();
      if (!bizNumber.part1 || !bizNumber.part2 || !bizNumber.part3) {
        alert('사업자 등록번호 필수 입력 항목입니다.');
        return;
      }

      // TODO: 사업자번호 인증 실행
      const result = await props.updateBizInfo({
        businessNo1: bizNumber.part1,
        businessNo2: bizNumber.part2,
        businessNo3: bizNumber.part3,
      });
      // alert('사업자 번호가 인증되었습니다.');
      isBiznumberCertificated.value = result;
    };

    const goToNextStep = () => {
      console.log('props.bizInfo : ' + props.bizInfo);
      if (props.bizInfo && isBiznumberCertificated.value) {
        props.updateCurrentStep(2);
      } else {
        alert('사업자 번호 인증이 필요합니다.');
      }
    };

    // html input element
    const input2 = ref();
    const input3 = ref();
    const nextFocus = (value, nextTarget) => {
      if (nextTarget === 2) {
        if (value.length >= 3) {
          input2.value.$el.children[0].focus();
        }
      }
      if (nextTarget === 3) {
        if (value.length >= 2) {
          input3.value.$el.children[0].focus();
        }
      }
    };

    return {
      data,
      classes,
      openedTermsIndex,
      handleOpenTerms,
      bizNumber,
      isBiznumberCertificated,
      handleCertificateBizNumber,
      goToNextStep,
      input2,
      input3,
      nextFocus,
    };
  },
});
</script>

<style lang="scss" scoped>
.CompnayNumberInput {
  width: 225px;
}
</style>
