<template>
  <span :class="['inline-flex', className]">
    <span style="margin-top: 2px">
      <IconBase :width="5" :height="5">
        <RequiredStarIcon></RequiredStarIcon>
      </IconBase>
    </span>
    <span class="ml-1">
      <slot></slot>
    </span>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import RequiredStarIcon from '@/components/icons/RequiredStarIcon.vue';
import IconBase from '@/components/icons/IconBase.vue';

export default defineComponent({
  name: 'RequiredLabel',
  components: {
    RequiredStarIcon,
    IconBase,
  },
  props: {
    className: { type: String, required: false },
  },
});
</script>

<style lang="scss" scoped></style>
