
import Input from '@/components/Input.vue';
import RequiredLabel from '@/components/RequiredLabel.vue';
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import IconBase from '@/components/icons/IconBase.vue';
import Checkbox from '@/components/Checkbox.vue';
import Container from '@/components/Container.vue';
import HiddenFileInput from '@/components/HiddenFileInput.vue';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import uploadSignupImageFiles from '@/utils/uploadSignupImageFiles';
import { PartnerAuthApiJoinPartnerUsingPOST1Request } from '@/openapi';
import getServerErrorMessage from '@/utils/getServerErrorMessage';
import Select from '@/components/Select.vue';
import FileImage from '@/components/icons/FileImage.vue';
import bankCode from '@/constants/bankCode';

export default defineComponent({
  name: 'Step2',
  mixins: [scrollToTopOnMountMixin],
  props: {
    updateCurrentStep: {
      type: Function,
      required: true,
    },
    bizInfo: {
      type: Object,
    },
  },
  components: {
    FileImage,
    Input,
    Select,
    RequiredLabel,
    CheckCircleIcon,
    IconBase,
    Checkbox,
    HiddenFileInput,
    Container,
  },
  setup(props) {
    // 파트너 가입 데이터
    const partnerData = ref<any>({} as any);
    const bankOptions = ref(bankCode);
    const isBankAuth = ref<boolean>(false);
    const isSamePerson = ref(false);
    const imgFiles = ref({
      bankbookCopy: '',
      businessLicense: '',
    });
    // 'START', 'VALID', 'NOT_VALID'
    const accountValid = ref({
      email: 'START',
      emailNotDuplicate: 'START',
      password: 'START',
      passwordConfirm: 'START',
    });
    const onlyNumber = (input) => {
      console.log(input);
      partnerData.value.bankAccount = input.replace(/[^0-9]/g, '');
    };
    const validateEmail = (email) => {
      console.log('email');
      const emailReg = /^[a-z0-9_+.-]+@([a-z0-9-]+\.)+[a-z0-9]{2,8}$/;
      accountValid.value.email = emailReg.test(email) ? 'VALID' : 'NOT_VALID';
      accountValid.value.emailNotDuplicate = 'START';
    };

    const validatePassword = (password) => {
      console.log('password');
      if (!password) {
        accountValid.value.password = 'START';
        return;
      }
      const pwReg =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@!%*#?&^~+=`-])[A-Za-z\d$@!%*#?&^~+=`-]{6,15}$/;
      accountValid.value.password = pwReg.test(password)
        ? 'VALID'
        : 'NOT_VALID';
      if (partnerData.value.passwordConfirm) {
        validatePasswordConfirm(partnerData.value.passwordConfirm);
      }
    };

    const validatePasswordConfirm = (passwordConfirm) => {
      console.log('passwordConfirm');
      if (!partnerData.value.password) {
        accountValid.value.passwordConfirm = 'START';
        return;
      }
      accountValid.value.passwordConfirm =
        partnerData.value.password === passwordConfirm ? 'VALID' : 'NOT_VALID';
    };

    const emailCheck = async () => {
      console.log('email check');
      if (!partnerData.value.email || !accountValid.value.email) {
        return;
      }
      try {
        const { data } = await partnerAPI.partnerAuth.checkEmailUsingPOST1({
          email: partnerData.value.email,
        });
        const { success, message } = data as any;
        accountValid.value.emailNotDuplicate = success ? 'VALID' : 'NOT_VALID';
        console.log(success, message);
      } catch (e) {
        alert(getServerErrorMessage(e));
      }
    };

    onMounted(() => {
      if (!props.bizInfo) {
        alert('사업자 인증이 필요합니다.'); // FIXME: remove comment
        props.updateCurrentStep(1); // FIXME: remove comment
      } else {
        console.log(props.bizInfo);

        partnerData.value = props.bizInfo;
        // console.log(partnerData.value["businessNumber"]);
      }
    });
    const goToNextStep = async () => {
      if (accountValid.value.email !== 'VALID') {
        alert('아이디를 이메일 형식으로 입력해 주세요.');
        return;
      }
      if (accountValid.value.emailNotDuplicate !== 'VALID') {
        alert('이메일 중복을 확인해 주세요.');
        return;
      }
      if (accountValid.value.password !== 'VALID') {
        alert(
          '비밀번호는 영문, 숫자, 특수문자 조합으로 6~15자를 입력해주세요.'
        );
        return;
      }
      if (accountValid.value.passwordConfirm !== 'VALID') {
        alert('비밀번호 확인이 일치하지 않습니다.');
        return;
      }
      if (!isBankAuth.value || !partnerData.value.accountHolder) {
        alert('예금주명을 조회해 주세요.');
        return;
      }

      console.log('partnerData : ', partnerData.value);
      partnerData.value['ceoMobile'] =
        partnerData.value['ceoMobile1'] +
        partnerData.value['ceoMobile2'] +
        partnerData.value['ceoMobile3'];
      partnerData.value['managerMobile'] =
        partnerData.value['managerMobile1'] +
        partnerData.value['managerMobile2'] +
        partnerData.value['managerMobile3'];
      partnerData.value['paymentManagerMobile'] =
        partnerData.value['paymentManagerMobile1'] +
        partnerData.value['paymentManagerMobile2'] +
        partnerData.value['paymentManagerMobile3'];
      partnerData.value['csTelNo'] =
        partnerData.value['csTelNo1'] +
        partnerData.value['csTelNo2'] +
        partnerData.value['csTelNo3'];

      if (partnerData.value['receiveMarketingEmailCheck'] === 'on') {
        partnerData.value['receiveMarketingEmail'] = true;
      } else {
        partnerData.value['receiveMarketingEmail'] = false;
      }
      if (partnerData.value['receiveMarketingSmsCheck'] === 'on') {
        partnerData.value['receiveMarketingSms'] = true;
      } else {
        partnerData.value['receiveMarketingSms'] = false;
      }

      const submitData = { param: { ...partnerData.value } };
      try {
        const { data } = await partnerAPI.partnerAuth.joinPartnerUsingPOST1(
          submitData as PartnerAuthApiJoinPartnerUsingPOST1Request
        );
        if ((data as any).success === true) {
          props.updateCurrentStep(3);
        } else {
          alert((data as any).message);
        }
      } catch (e) {
        console.error(e);
        alert(getServerErrorMessage(e));
      }

      // if (props.bizInfo) {
      //   props.updateCurrentStep(2);
      // } else {
      //   alert('사업자 번호 인증이 필요합니다.');
      // }
    };

    const handleBankAuth = async () => {
      try {
        const { data } = await partnerAPI.partnerAuth.companyCheckUsingPOST({
          param: {
            bankCode: partnerData.value.bankCode,
            bankAccount: partnerData.value.bankAccount,
          },
        });
        if ((data as any).success) {
          isBankAuth.value = true;
          partnerData.value.accountHolder = (data as any).data;
        } else {
          isBankAuth.value = false;
          partnerData.value.accountHolder = '';
        }
        alert((data as any).message);
      } catch (e) {
        console.error(e);
        isBankAuth.value = false;
        partnerData.value.accountHolder = '';
        alert(getServerErrorMessage(e));
      }
    };

    const handleChangeFile = (fieldName: string) => async (e) => {
      // TODO: 서버에서 이미지 업로드에 인증 토큰 요구함. 파트너 가입에서 쓸 수 있는 방법 필요.
      const [url] = await uploadSignupImageFiles({
        files: e.target.files as FileList,
      });
      imgFiles.value[fieldName] = e.target.files && e.target.files[0].name;
      console.log('url', url, e.target, fieldName);
      if (url) {
        partnerData.value[fieldName] = url;
      } else {
        alert('이미지 업로드에 실패했습니다.');
      }
    };

    const handleOpenSearchAddress = () => {
      new (window.daum as any).Postcode({
        oncomplete: function (data) {
          partnerData.value['postNo'] = data.zonecode;
          partnerData.value['address1'] = data.address;
          // console.group('postcode');
          // console.log('address ', data.address);
          // console.log('zonecode ', data.zonecode);
          // console.groupEnd();
        },
      }).open();
    };

    const handleManagerField = () => {
      const {
        managerName,
        managerMobile1,
        managerMobile2,
        managerMobile3,
        managerEmail,
      } = partnerData.value;
      if (isSamePerson.value) {
        // 복사하기
        partnerData.value.paymentManagerName = managerName;
        partnerData.value.paymentManagerMobile1 = managerMobile1;
        partnerData.value.paymentManagerMobile2 = managerMobile2;
        partnerData.value.paymentManagerMobile3 = managerMobile3;
        partnerData.value.paymentManagerEmail = managerEmail;
      } else {
        partnerData.value.paymentManagerName = '';
        partnerData.value.paymentManagerMobile1 = '';
        partnerData.value.paymentManagerMobile2 = '';
        partnerData.value.paymentManagerMobile3 = '';
        partnerData.value.paymentManagerEmail = '';
      }
    };

    const openMobileAuth = async () => {
      const { data } = await partnerAPI.memberAuth.identificationInfoUsingGET();
      const encodeData = (data as any).data;

      const form = document.createElement('form');
      form.setAttribute('name', 'formChk');
      form.setAttribute(
        'action',
        'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb'
      );
      form.setAttribute('target', 'popupChk');

      const field = document.createElement('input');
      field.setAttribute('name', 'm');
      field.setAttribute('value', 'checkplusService');
      form.appendChild(field);

      const field2 = document.createElement('input');
      field2.setAttribute('name', 'EncodeData');
      field2.setAttribute('value', encodeData);
      form.appendChild(field2);

      const htmlForm = document.getElementById('form');
      const chkForm = document.getElementById('formChk');
      if (chkForm) {
        if (chkForm.parentNode != null) {
          chkForm.parentNode.removeChild(chkForm);
        }
      }
      if (htmlForm != null) {
        htmlForm.append(form);
      }

      window.open(
        '',
        'popupChk',
        'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no'
      );
      form.submit();
    };

    const mobileAuthCallbackHandler = ({ data, origin }) => {
      if (origin.includes('api.blacklot.com')) {
        console.log('휴대퐁 인증 콜백 들어옴');
        console.log(data);
        const { name, birth, dupInfo, gender, mobile } = JSON.parse(data);
        partnerData.value['ceoMobile'] = mobile;
        partnerData.value['ceoMobile1'] = mobile.substring(0, 3);
        partnerData.value['ceoMobile2'] = mobile.substring(3, 7);
        partnerData.value['ceoMobile3'] = mobile.substring(7);
        // input.value.name = decodeURI(name)
        // input.value.birth = birth
        // input.value.dupInfo = dupInfo
        // input.value.gender = gender === '1' ? '남자' : '여자'
        // input.value.tel = mobile
      }
    };

    onMounted(() => {
      window.addEventListener('message', mobileAuthCallbackHandler);
    });
    onUnmounted(() => {
      window.removeEventListener('message', mobileAuthCallbackHandler);
    });

    return {
      isSamePerson,
      bankOptions,
      partnerData,
      isBankAuth,
      imgFiles,
      accountValid,
      validateEmail,
      onlyNumber,
      validatePassword,
      validatePasswordConfirm,
      emailCheck,
      handleManagerField,
      handleBankAuth,
      handleChangeFile,
      openMobileAuth,
      handleOpenSearchAddress,
      goToNextStep,
    };
  },
});
