
import Input from '@/components/Input.vue';
import { defineComponent, ref, reactive } from 'vue';
import RequiredLabel from '@/components/RequiredLabel.vue';
import BusinessIdIcon from '@/components/icons/BusinessIdIcon.vue';
import ArrowDownIcon from '@/components/icons/ArrowDownIcon.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import IconBase from '@/components/icons/IconBase.vue';
import Container from '@/components/Container.vue';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import TermsContent from '@/views/SignUp/TermsContent.vue';
import PrivacyContent from '@/views/SignUp/PrivacyContent.vue';

export default defineComponent({
  name: 'Step1',
  mixins: [scrollToTopOnMountMixin],
  props: {
    updateCurrentStep: {
      type: Function,
      required: true,
    },
    updateBizInfo: {
      type: Function,
      required: true,
    },
    bizInfo: {
      type: Object,
    },
  },
  components: {
    Input,
    RequiredLabel,
    BusinessIdIcon,
    IconBase,
    CheckCircleIcon,
    ArrowDownIcon,
    Container,
    TermsContent,
    PrivacyContent,
  },
  setup(props) {
    const data = ref(0);

    const classes = {
      collapseHead: 'flex items-center h-18 px-6 border-t cursor-pointer',
      collapseBody:
        'border-t p-12 break-words font-sans whitespace-pre-wrap text-gray-400',
      collapseHeadTitle: 'ml-2 font-bold',
    };

    const openedTermsIndex = ref(1); // 약관 영역 toggle을 위한 인덱스

    const handleOpenTerms = (index: number) => {
      openedTermsIndex.value = index;
    };

    const bizNumber = reactive({
      part1: '',
      part2: '',
      part3: '',
    });

    const isBiznumberCertificated = ref(false);

    const handleCertificateBizNumber = async () => {
      console.group('cert biznumber');
      console.log('bizNumber.part1', bizNumber.part1);
      console.log('bizNumber.part2', bizNumber.part2);
      console.log('bizNumber.part3', bizNumber.part3);
      console.groupEnd();
      if (!bizNumber.part1 || !bizNumber.part2 || !bizNumber.part3) {
        alert('사업자 등록번호 필수 입력 항목입니다.');
        return;
      }

      // TODO: 사업자번호 인증 실행
      const result = await props.updateBizInfo({
        businessNo1: bizNumber.part1,
        businessNo2: bizNumber.part2,
        businessNo3: bizNumber.part3,
      });
      // alert('사업자 번호가 인증되었습니다.');
      isBiznumberCertificated.value = result;
    };

    const goToNextStep = () => {
      console.log('props.bizInfo : ' + props.bizInfo);
      if (props.bizInfo && isBiznumberCertificated.value) {
        props.updateCurrentStep(2);
      } else {
        alert('사업자 번호 인증이 필요합니다.');
      }
    };

    // html input element
    const input2 = ref();
    const input3 = ref();
    const nextFocus = (value, nextTarget) => {
      if (nextTarget === 2) {
        if (value.length >= 3) {
          input2.value.$el.children[0].focus();
        }
      }
      if (nextTarget === 3) {
        if (value.length >= 2) {
          input3.value.$el.children[0].focus();
        }
      }
    };

    return {
      data,
      classes,
      openedTermsIndex,
      handleOpenTerms,
      bizNumber,
      isBiznumberCertificated,
      handleCertificateBizNumber,
      goToNextStep,
      input2,
      input3,
      nextFocus,
    };
  },
});
