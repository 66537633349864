
import { defineComponent } from 'vue';
import RequiredStarIcon from '@/components/icons/RequiredStarIcon.vue';
import IconBase from '@/components/icons/IconBase.vue';

export default defineComponent({
  name: 'RequiredLabel',
  components: {
    RequiredStarIcon,
    IconBase,
  },
  props: {
    className: { type: String, required: false },
  },
});
